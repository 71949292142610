import { makeStyles } from '@material-ui/core';
import { secondaryBgGray, baseBlue } from '../../../constants/colors';
import { SIZE, WEIGHT } from '../../../constants/fonts';
import largeQuoteImage from '../../../images/large-quote.png';

const leftQuotePadding = 80;
const leftQuoteImagePadding = leftQuotePadding - 55;

export const useTestimonialStyles = makeStyles((theme) => ({
    colorContainer: {
      backgroundColor: secondaryBgGray
    },
    container: {
      margin: '0px auto',
      maxWidth: 1440,
      backgroundColor: secondaryBgGray,
      padding: '32px 32px',
      [theme.breakpoints.up('md')]: {
        padding: '32px 32px 0px 32px',  
      }
    },
    testimonial: {
      backgroundImage: `url(${largeQuoteImage})`,
      backgroundPosition: '90% 55%',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 650,
      [theme.breakpoints.up('md')]: {
        backgroundPosition: '60% 50%',
        flexDirection: 'row',
        alignItems: 'flex-end',
        height: 420,
      },
    },
    textContainer: {
      width: '100%',
      height: '50%',
      [theme.breakpoints.up('md')]: {
        width: '60%',
        height: '100%',
        justifyContent: 'left',
      },
    },
    imageContainer: {
      position: 'relative',
      width: '100%',
      height: '50%',
      [theme.breakpoints.up('md')]: {
        width: '40%',
        height: '100%',
      },
    },
    smallQuoteImage: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: leftQuoteImagePadding
      }
      
    },
    customerImage: {
        position: 'absolute',
        bottom: 0,
        maxWidth: '100%',
        maxHeight: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '100%',
        maxHeight: '100%',
        left: 0,
        bottom: 0,
      },
    },
    quoteBody: {
      '&.MuiTypography-body1': {
        fontSize: SIZE.px16,
        paddingTop: 10,
        paddingLeft: 16,
        [theme.breakpoints.up('md')]: {
          paddingLeft: leftQuotePadding,
          
        },
      }
    },
    custName: {
      '&.MuiTypography-body2': {
        color: baseBlue,
        fontWeight: WEIGHT.bold,
        paddingTop: 20,
        [theme.breakpoints.up('md')]: {
          paddingLeft: leftQuotePadding,
        },
      }
    },
    custSince: {
      '&.MuiTypography-body2': {
        [theme.breakpoints.up('md')]: {
          paddingLeft: leftQuotePadding,
        },
      }
    },
  }));