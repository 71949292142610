export const emptyAppliances =
  'You must select and save your backup room preferences in order to proceed.';

export const emptyFinancing =
  'You must select financing method in order to proceed.';

export const limitErrors = {
  1: 'You have selected more appliances than your battery can support. Please select 5 items or fewer appliances.',
  2: 'You have selected more appliances than your batteries can support. Please select 10 items or fewer appliances.',
};

export const applianceOverload = difference =>
  `Add an additional ${(difference > 1) ? (difference + ' ') : ''}batter${(difference > 1) ? 'ies' : 'y'} to support this item.`;

export const failedProposalCreation =
  'We encountered an error. Please try again.';

export const NO_STATE = 'NO';
