export interface Address {
  city?: string
  postalCode?: string
  state?: string
  street?: string
}

export function formatAddress({city, postalCode = '', state = '', street}: Address): string {
  return [street, city, `${state} ${postalCode}`.trim()].filter(Boolean).join(', ');
}
