import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { testimonialText } from '../../../constants/landing';
import smallQuoteImage from '../../../images/small-quote.png';
import imageOne from '../../../images/testimonial-1.png';
import imageTwo from '../../../images/testimonial-2.png';
import imageThree from '../../../images/testimonial-3.png';


import { useTestimonialStyles } from './Testimonial.styles'
const imageMap = {
    1: {
        url: imageOne,
        alt: 'customer family'
    },
    2: {
        url: imageTwo,
        alt: 'customer couple'
    },
    3: {
        url: imageThree,
        alt: 'customer person'
    },
}

export default function Testimonial({ testimonialNumber }) {

    const classes = useTestimonialStyles();
    const image = imageMap[testimonialNumber];
    return (

        <div className={classes.testimonial}>
            <div className={classes.textContainer}>
                <img className={classes.smallQuoteImage} src={smallQuoteImage} alt='small quote' />
                <Typography className={classes.quoteBody} variant='body1'>{testimonialText[testimonialNumber].quote}</Typography>
                <Typography className={classes.custName} variant='body2'>{testimonialText[testimonialNumber].name}</Typography>
                <Typography className={classes.custSince} variant='body2'>{testimonialText[testimonialNumber].custSince}</Typography>

            </div>
            <div className={classes.imageContainer}>
                <img className={classes.customerImage} src={image.url} alt={image.alt} />
            </div>
        </div>
    )
}

Testimonial.propTypes = {
    testimonialNumber: PropTypes.number
}
