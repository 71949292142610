import { Typography } from '@material-ui/core';
import React from 'react';
import { useFooterStyles } from './Footer.styles';

const Footer = () => {
  const classes = useFooterStyles();

  return (
    <div className={classes.footer}>
      <Typography className={classes.copyright}>
        <strong>&copy; Sunrun</strong>
        &nbsp;&mdash;&nbsp;All Rights Reserved
      </Typography>
      <ul className={classes.links}>
        <li>
          <a href='https://www.sunrun.com/state-contractor-license-information'>
            State Contractor Licensing Information
          </a>
        </li>
        <li>
          <a href='https://www.sunrun.com/terms-of-service'>Terms</a>
        </li>
        <li>
          <a href='https://www.sunrun.com/privacy-policy'>Privacy Policy</a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
