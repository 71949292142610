import PropTypes from 'prop-types';
import React from 'react';
import { useBatteryContext } from '../../context/battery.context';
import useStyles from './Landing.styles';

const Landing = ({ children }) => {
  const classes = useStyles();
  const { isDrawerOpen, isMobile } = useBatteryContext();

  return (
    <main
      className={`${classes.content} ${
        isDrawerOpen && !isMobile
          ? `${classes.contentShift} ${classes.mainThemeShift}`
          : classes.main
      }`}
    >
      {children}
    </main>
  );
};

Landing.propTypes = {
  children: PropTypes.any,
};

export default Landing;
