import React from 'react';
import { SELECTED_FINANCING } from '../../constants/mixpanel';

import * as Constants from '../../constants/pricing';
import { useBatteryContext } from '../../context/battery.context';
import mixpanel from '../../mixpanel/mixpanel';
import { getMonthlyLoanPrice } from '../../utils/loanInfo';
import { getPriceText, FinancialProductType } from '../../utils/strings';
import PricingCard from './PricingCard';

const MONTHS_PER_YEAR = 12;

const LoanPricingCard = () => {
  const { loanInfo, pricingData, pricingSelection, setPricingSelection } =
    useBatteryContext();

  const onSelectLoan = () => {
    if (pricingSelection === FinancialProductType.LOAN) return;
    mixpanel.track(SELECTED_FINANCING);
    setPricingSelection(FinancialProductType.LOAN);
  };

  if (!loanInfo) return null;

  const monthlyInterestRate = loanInfo.apr / 100 / MONTHS_PER_YEAR;
  const numberOfPayments = loanInfo.term * MONTHS_PER_YEAR;
  const priceMonthly = pricingData
    ? getMonthlyLoanPrice(
        pricingData.totalPrice,
        monthlyInterestRate,
        numberOfPayments
      )
    : undefined;
  const priceMonthlyText = priceMonthly
    ? `${getPriceText(priceMonthly)}/mo`
    : undefined;

  const handleSpacePress = (e) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
      setPricingSelection(FinancialProductType.LOAN);
    }
  };

  return (
    <PricingCard
      title={`${loanInfo.term}-year loan`}
      primaryText={Constants.loanPrimaryText(loanInfo.apr)}
      secondaryText={Constants.LOAN_SECONDARY_TEXT}
      price={priceMonthlyText}
      isSelected={pricingSelection === FinancialProductType.LOAN}
      onClick={onSelectLoan}
      onKeyDown={handleSpacePress}
      expandable
    />
  );
};

export default LoanPricingCard;
