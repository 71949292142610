export const CASH_DEPOSIT_AMOUNT = 100;

export const CASH_PRIMARY_TEXT = 'Pay full amount upfront';
export const CASH_SECONDARY_TEXT = 'Own your system outright.';

export const loanPrimaryText = (apr) => `As low as ${apr}% APR`;

export const LOAN_SECONDARY_TEXT = `Goodleap, formerly Loanpal, is the largest solar and home battery loan provider in the U.S., offering a loan for your Battery Add-on Retrofit. Offer is subject to qualification. 
You will get a chance to review the full terms and conditions before you complete your loan application.
Maintain low monthly payments by leveraging the Federal Investment Tax Credit (ITC) for Residential Storage Systems. Consult your tax advisor to determine the applicability of this credit to your tax situation. Sunrun does not provide tax advice or guarantee that you can take the tax credit.
`;
