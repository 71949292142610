import { Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import React from 'react';
import {
  NEXT_STEPS_TITLE,
  NEXT_STEPS_SUBTITLE,
} from '../../../constants/landing';
import { useNextStepsStyles } from './NextSteps.styles';
import NextStepsAccordion from './NextStepsAccordion';

export default function NextSteps() {
  const classes: ClassNameMap = useNextStepsStyles();

  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <Typography className={classes.title}>{NEXT_STEPS_TITLE}</Typography>
        <Typography className={classes.subtitle}>
          {NEXT_STEPS_SUBTITLE}
        </Typography>
      </div>
      <NextStepsAccordion />
    </div>
  );
}
