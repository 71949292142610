import axios from 'axios';

function wait(ms = 1000) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    })
}

export default async function poll(url, parameters, waitTimeMs, maxAttempts) {
    for (let i = 0; i < maxAttempts; i++) {
        try {
            return await axios.get(url, { params: { ...parameters } });
        } catch (err) {
            await wait(waitTimeMs)
        }
    }
    throw new Error('A successful response was not achieved in the maximum number of tries');
}