import { makeStyles } from '@material-ui/core';

import {
  baseBlue,
  bodyBlack,
  faintGray,
  lightError,
  white,
} from '../../constants/colors';
import { SIZE, WEIGHT } from '../../constants/fonts';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 0,
    color: bodyBlack,
    padding: '21px 28px',
    fontSize: SIZE.px14,
    textTransform: 'none',

    '&.MuiButtonBase-root.Mui-disabled': {
      opacity: 0.5,
    },

    '& .MuiButton-label': {
      fontWeight: WEIGHT.medium,
    },

    '&.primary': {
      background: baseBlue,
      color: 'white',
      '& .MuiButton-label': {
        fontWeight: WEIGHT.bolder,
      },
    },

    '&.secondary': {
      border: `1px solid ${white}`,
      background: 'transparent',
      padding: '12px 18px',
      [theme.breakpoints.up('xl')]: {
        padding: '12px 30px',
      },

      '& .MuiButton-label': {
        color: white,
        fontSize: SIZE.px12,
        fontWeight: WEIGHT.bolder,
        letterSpacing: 1,
        lineHeight: 1.5,
        textTransform: 'uppercase',
        [theme.breakpoints.up('xl')]: {
          fontSize: SIZE.px18,
          lineHeight: 1,
        },
      },
    },

    '&.tertiary': {
      padding: '1rem 0',
      textDecoration: 'underline',

      '& .MuiButton-label': {
        fontWeight: WEIGHT.normal,
      },
    },

    '&.rounded': {
      padding: 0,
      height: 48,
      minWidth: 48,
      borderRadius: '50%',
      border: `2px solid ${faintGray}`,
      boxSizing: 'border-box',
      '& .MuiButton-label': {
        fontSize: SIZE.px16,
        color: faintGray,
      },
    },

    '&.error': {
      backgroundColor: lightError,
      height: 27,
      fontSize: SIZE.px16,
      padding: 0,
      lineHeight: 1.5,
    },
  },
}));

export default useStyles;
