import { CircularProgress, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import Card from '../Card/Card';
import SectionTitle from '../SectionTitle/SectionTitle';
import useStyles from './Pricing.styles';

const PricingCard = ({
  expandable,
  isSelected,
  onClick,
  price,
  primaryText,
  secondaryText,
  title,
  onKeyDown,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(
    (event) => {
      event.stopPropagation();
      setExpanded(!expanded);
    },
    [expanded]
  );

  const classes = useStyles();

  return (
    <>
      <SectionTitle variant='h3'>{title}</SectionTitle>
      <Card
        className={classes.card}
        onClick={onClick}
        onKeyDown={onKeyDown}
        variant={isSelected ? 'selected' : 'default'}
        data-testid='pricingCard'
        tabIndex={0}
      >
        <div className={classes.cardContent}>
          {price ? (
            <>
              <div>
                <Typography className={classes.text}>{primaryText}</Typography>
                {expandable ? (
                  <Typography
                    onClick={toggleExpanded}
                    className={classNames(
                      classes.underline,
                      expanded ? classes.closeText : classes.secondaryText
                    )}
                  >
                    {expanded ? 'Close' : 'More Details'}
                  </Typography>
                ) : (
                  <Typography className={classes.secondaryText}>
                    {secondaryText}
                  </Typography>
                )}
              </div>
              <div className={classes.priceContainer}>
                <Typography className={classes.priceText}>{price}</Typography>
                <Typography
                  className={classNames(classes.secondaryText, {
                    [classes.selected]: isSelected,
                  })}
                >
                  {isSelected ? 'Selected' : 'Select'}
                </Typography>
              </div>
            </>
          ) : (
            <CircularProgress className={classes.loading} size={40} />
          )}
        </div>
        {expanded && (
          <Typography className={classes.text}>{secondaryText}</Typography>
        )}
      </Card>
    </>
  );
};

PricingCard.propTypes = {
  expandable: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  price: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  title: PropTypes.string,
  onKeyDown: PropTypes.func,
};

export default PricingCard;
