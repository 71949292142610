import { Typography } from '@material-ui/core';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import {
  priceComparisonDisclaimer,
  priceComparisonTitle,
} from '../../../constants/landing';
import { useBatteryContext } from '../../../context/battery.context';

import useWindowSize from '../../../hooks/useWindowSize';
import NavigationDot from '../../../images/NavigationDot.js';
import { usePriceComparisonStyles } from './PriceComparison.styles';
import PriceComparisonCard from './PriceComparisonCard';

const isInRange = (value, [startValue, endValue]) => {
  if (value >= startValue && value < endValue) return true;
  return false;
};

const getScrollIndex = (value, ranges) => {
  for (let i = 0; i < ranges.length; i++) {
    if (isInRange(value, ranges[i])) return i;
  }
  return ranges.length - 1;
};

export default function PriceComparison() {
  const classes = usePriceComparisonStyles();
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const horizontalScrollContainerRef = useRef(0);
  const previousScrollIndex = useRef(0);
  const [screenWidth] = useWindowSize();
  const { isDrawerOpen } = useBatteryContext();

  // Determines the starting, center, and end scroll amounts
  const containerWidth = horizontalScrollContainerRef.current.scrollWidth;
  const startScroll = 0;
  const endScroll = isDrawerOpen
    ? containerWidth - (screenWidth - 390)
    : containerWidth - screenWidth;
  const centerScroll = isDrawerOpen ? endScroll / 2 : (endScroll - 390) / 2;
  const scrollLeftPositions = [startScroll, centerScroll, endScroll];

  // Creates an array of tuples that correspond to the index ranges for child elements
  const numberOfItems = 3;
  let indexRanges = [];
  let currentCutoff = 0;
  for (let i = 0; i < numberOfItems; i++) {
    let newCutoff = (endScroll * (i + 1)) / numberOfItems;
    indexRanges.push([currentCutoff, newCutoff]);
    currentCutoff = newCutoff;
  }

  // Stores the previous scroll index as a ref and only updates the state when the index changes
  // to avoid unnecessary re-renders
  const onScroll = () => {
    const currentContainerScroll =
      horizontalScrollContainerRef.current.scrollLeft;
    const currentScrollIndex = getScrollIndex(
      currentContainerScroll,
      indexRanges
    );

    if (previousScrollIndex.current !== currentScrollIndex) {
      setCurrentCardIndex(currentScrollIndex);
    }
    previousScrollIndex.current = currentScrollIndex;
  };

  const scrollContainerTo = (scrollAmount) => {
    horizontalScrollContainerRef.current.scroll({ top: 0, left: scrollAmount });
  };

  return (
    <div>
      <Typography className={classes.title} variant='h2'>
        {priceComparisonTitle}
      </Typography>
      <div className={classes.navigation}>
        {scrollLeftPositions.map((leftPosition, i) => (
          <Typography
            key={`nav-item-${i}`}
            onClick={() =>
              horizontalScrollContainerRef.current.scroll({
                top: 0,
                left: leftPosition,
              })
            }
            className={classnames(classes.navigationText, {
              [classes.navigationTextSelected]: currentCardIndex === i,
            })}
          >
            {i + 1} Batter{i > 0 ? 'ies' : 'y'}
          </Typography>
        ))}
      </div>
      <div
        ref={horizontalScrollContainerRef}
        className={
          isDrawerOpen
            ? classes.horizontalScrollContainerShifted
            : classes.horizontalScrollContainer
        }
        onScroll={onScroll}
      >
        <PriceComparisonCard numberOfBatteries={1} />
        <PriceComparisonCard numberOfBatteries={2} />
        <PriceComparisonCard numberOfBatteries={3} />
      </div>
      <div className={classes.dots}>
        {scrollLeftPositions.map((leftPosition, i) => (
          <NavigationDot
            key={`nav-dot-${i}`}
            onClick={() => scrollContainerTo(leftPosition)}
            selected={currentCardIndex === i}
          />
        ))}
      </div>
      <Typography className={classes.disclaimer}>
        {priceComparisonDisclaimer}
      </Typography>
    </div>
  );
}
