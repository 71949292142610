import React, { useEffect } from 'react';

import { NotEligibleTitle, NotEligibleBody } from '../../constants/info';
import { OOM_PAGE } from '../../constants/mixpanel';
import mixpanel from '../../mixpanel/mixpanel';
import Footer from './Footer/Footer';
import InfoPage from './InfoPage/InfoPage';
import Landing from './Landing';

const LandingNotEligible = () => {
  useEffect(() => mixpanel.track(OOM_PAGE), []);

  return (
    <Landing>
      <InfoPage title={NotEligibleTitle}>{NotEligibleBody}</InfoPage>
      <Footer />
    </Landing>
  );
};

export default LandingNotEligible;
