import axios from 'axios';

import { BATTERY_REC_API } from '../constants/baseUrls';

export async function createRetrofitOpportunity({
  email,
  env,
  opptyId,
  prospectId,
}) {
  return axios.post(`${BATTERY_REC_API[env]}/v1/retrofitOpportunity`, {
    email,
    opptyId,
    prospectId,
  });
}

export async function getRetrofitOpportunities(email, env) {
  return axios.get(
    `${BATTERY_REC_API[env]}/v1/retrofitOpportunity?email=${email}`
  );
}
