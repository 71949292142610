import { Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { useBatteryContext } from '../../context/battery.context';
import useStyles from './ResponsiveDrawer.styles';

const ResponsiveDrawer = ({ children }) => {
  const classes = useStyles();
  const { isDrawerOpen, toggleDrawer, isMobile } = useBatteryContext();

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant={isMobile ? "responsive" : "persistent"}
      open={isDrawerOpen}
      onClose={toggleDrawer('Close Cart')}
      anchor='right'
      ModalProps={{
        style: { zIndex: 1 }
      }}
    >
      {children}
    </Drawer>
  );
};

ResponsiveDrawer.propTypes = {
  children: PropTypes.any,
};

export default ResponsiveDrawer;
