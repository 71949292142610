import { Typography } from '@material-ui/core';
import Slider from 'react-slick';

import {
  ComparisonSubtitle,
  ComparisonTitle,
  OneBatterySummary,
  OneBatteryTitle,
  ThreeBatterySummary,
  ThreeBatteryTitle,
  TwoBatterySummary,
  TwoBatteryTitle,
} from '../../../constants/landing';
import useWindowSize from '../../../hooks/useWindowSize';
import ComparisonList from './ComparisonList';
import { useProductComparisonStyles } from './ProductComparison.styles';

export default function ProductComparison() {
  const classes = useProductComparisonStyles();
  const [width] = useWindowSize();

  const settings = {
    slidesToShow: width > 480 ? 3 : 1,
    dots: width > 480 ? false : true,
    arrows: false,
  };
  return (
    <div className={classes.container}>
      <div className={classes.headline}>
        <Typography className={classes.comparisonTitle} variant='h2'>
          {ComparisonTitle}
        </Typography>
        <Typography className={classes.comparisonSubtitle}>
          {ComparisonSubtitle}
        </Typography>
      </div>
      <Slider {...settings}>
        <ComparisonList
          title={OneBatteryTitle}
          summary={OneBatterySummary}
          numberOfAppliances={5}
          noBorder
        />
        <ComparisonList
          title={TwoBatteryTitle}
          summary={TwoBatterySummary}
          numberOfAppliances={10}
        />
        <ComparisonList
          title={ThreeBatteryTitle}
          summary={ThreeBatterySummary}
          numberOfAppliances={15}
        />
      </Slider>
    </div>
  );
}
