import axios from 'axios';
import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useCallback,
} from 'react';

import { SPLAT_URL, BATTERY_REC_API } from '../constants/baseUrls';
import { NO_STATE } from '../constants/errors';
import {
  ERROR_NO_OPPTY,
  financingOptionText,
  TOGGLED_CART,
} from '../constants/mixpanel';
import mixpanel, { trackUser } from '../mixpanel/mixpanel';
import { getApplianceErrors } from '../utils/appliances';
import {
  appliancesFromPreferences,
  getDesignPreferences,
  setDesignPreferences,
  updatePreferences,
} from '../utils/backupPreferences';
import getEnv, { getQueryParam } from '../utils/environment';
import { getLoanInfo as getLoanInformation } from '../utils/loanInfo';
import {
  createRetrofitOpportunity,
  getRetrofitOpportunities,
} from '../utils/opportunity';
import poll from '../utils/poll';
import { getPricing } from '../utils/pricing';
import { generateProposal } from '../utils/proposal';
import { Rollbar } from '../utils/rollbar';

const MOBILE_MAX_WIDTH = 768;
const BatteryContext = createContext(null);

const calcMobile = () => window.innerWidth <= MOBILE_MAX_WIDTH;

const BatteryProvider = (props) => {
  const [env] = useState(getEnv(window.location.host));
  const [isMobile, setMobile] = useState(calcMobile(window.innerWidth));
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [step, setStep] = useState(0);

  const [batteryQuantity, setBatteryQuantity] = useState(1);
  const [loanInfo, setLoanInfo] = useState();
  const [opportunity, setOpportunity] = useState();
  const [parentOpportunities, setParentOpportunities] = useState();
  const [preferences, setPreferences] = useState({});
  const [pricingData, setPricingData] = useState();
  const [pricingSelection, setPricingSelection] = useState();

  const [nonAllowedAppliances, setNonAllowedAppliances] = useState([]);
  const [selectedAppliances, setSelectedAppliances] = useState([]);
  const [exceededLimit, setExceededLimit] = useState(0);

  const [profile, setProfile] = useState({});

  const [isCheckingOut, setIsCheckingOut] = useState(false);

  function cleanApplianceData() {
    setExceededLimit(0);
    setNonAllowedAppliances([]);
  }

  const createRetrofitOppty = useCallback(
    async (parentOppty, email) => {
      const { data: retrofitOppty } = await createRetrofitOpportunity({
        email,
        env,
        opptyId: parentOppty.id,
        prospectId: parentOppty.prospectId,
      });

      setOpportunity(retrofitOppty);
    },
    [env]
  );

  async function submitProposal() {
    const proposal = generateProposal({
      batteryQuantity,
      loanInfo,
      opportunity,
      pricingData,
      pricingSelection,
    });

    try {
      const postResponse = await axios.post(
        `${BATTERY_REC_API[env]}/v1/proposals`,
        proposal
      );
      const { externalId } = postResponse.data;
      const response = await poll(
        `${BATTERY_REC_API[env]}/v1/proposals`,
        { externalId },
        3000,
        20
      );
      const { proposalId, prospectId } = response.data;

      const { data: proposalToken } = await axios.get(
        `${BATTERY_REC_API[env]}/v1/proposalToken?prospectId=${prospectId}&opptyId=${opportunity.id}&env=${env}`,
        {
          responseType: 'text',
        }
      );

      const proposalURL = `${SPLAT_URL[env]}/go-solar/${proposalToken}/checkout?externalProposalId=${proposalId}`;
      const topWindow = window.parent || window;
      topWindow.location.href = proposalURL;

      return;
    } catch (err) {
      setIsCheckingOut(false);
      console.error(err);
      throw err;
    }
  }

  async function getLoanInfo(oppty) {
    try {
      const loanData = await getLoanInformation(oppty, env);
      setLoanInfo(loanData);
    } catch (err) {
      Rollbar.error('Error while getting loan info', err);
      console.error(err);
    }
  }

  function getNonAllowedAppliances() {
    const applianceErrors = getApplianceErrors(
      selectedAppliances,
      batteryQuantity
    );
    setExceededLimit(applianceErrors.exceededLimit);
    setNonAllowedAppliances(applianceErrors.nonAllowedAppliances);
    return applianceErrors;
  }

  async function getPricingData(numberOfBatteries, oppty) {
    try {
      const batteryPrice = await getPricing(numberOfBatteries, oppty, env);
      setPricingData(batteryPrice);
    } catch (err) {
      Rollbar.error('Error getting pricing data', err);
      console.error(err);
    }
  }

  async function initBackupPreferences(prospectId) {
    try {
      const { data } = await getDesignPreferences(prospectId, env);
      setPreferences(data);

      const selected = appliancesFromPreferences(data);
      setSelectedAppliances(selected);
    } catch (err) {
      Rollbar.error('Error getting backup preferences', err);
      console.error(err);
    }
  }

  async function saveBackupPreferences() {
    try {
      const newDesignPreferences = updatePreferences(
        preferences,
        selectedAppliances
      );
      await setDesignPreferences(newDesignPreferences, env);
    } catch (err) {
      Rollbar.error('Error saving backup preferences', err);
      console.error(err);
    }
  }

  const toggleDrawer = (source) => (event) => {
    event.stopPropagation();
    mixpanel.track(TOGGLED_CART, {
      'Battery Count': batteryQuantity,
      'Backup Loads': selectedAppliances.join(', '),
      'Financing Option': financingOptionText[pricingSelection],
      'Starting State': isDrawerOpen ? 'Open' : 'Closed',
      Source: source,
    });
    return setDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    window.addEventListener('resize', setMobile(calcMobile()));
    return () => {
      window.removeEventListener('resize', setMobile(calcMobile()));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth, setMobile]);

  useEffect(() => {
    function getCustomerInfo({ data, origin }) {
      const currentEnv = getEnv(window.location.host);
      // return early if origin doesn't match expected Splat URL
      if (origin !== SPLAT_URL[currentEnv]) {
        // clean up console by removing unnecessary logs
        if (data && data.source && data.source.includes('react-devtools'))
          return;

        console.error('Unallowed origin', origin);
        return;
      }
      setProfile(JSON.parse(data));
    }

    window.addEventListener('message', getCustomerInfo);

    return () => window.removeEventListener('message', getCustomerInfo);
  }, []);

  useEffect(() => {
    const emailAddress = profile.emailAddress || getQueryParam('email');

    async function getRetrofitOppportunity() {
      try {
        // oppties are sorted by desc by created date
        let { data: oppties } = await getRetrofitOpportunities(
          emailAddress,
          env
        );

        // If there is only one, it is a retrofit opportunity
        if (oppties.length === 1) {
          setOpportunity(oppties[0]);
        } else {
          // if there are no oppties available, show ineligible page
          if (!oppties.length) {
            setOpportunity({
              state: NO_STATE,
            });
            return;
          }

          // there are more than one valid oppties, so we'll let the user to decide which to use in PageSelector
          setParentOpportunities(oppties);
        }
      } catch (err) {
        // We're seeing errors returned because our selected parent oppty has
        // "Stage not supported for Additional System/Meter." We should collect stage
        // from SFDC so that we can only select parents with the correct criteria.
        // For now, we have no way to tell which parent oppties are viable, so we have
        // no choice but to set the oppty state to a value that will display the page
        // that says we can't help them at this time.
        mixpanel.track(ERROR_NO_OPPTY, { Error: err });
        setOpportunity({
          state: NO_STATE,
        });
        Rollbar.error(err);
        console.error(err);
      }
    }

    if (!env || !emailAddress) {
      if (
        Object.keys(profile).length &&
        !emailAddress &&
        !getQueryParam('state')
      ) {
        setOpportunity({
          state: NO_STATE,
        });
        const message = 'No email address available!';
        Rollbar.error(message, profile);
        console.log(message);
      }
      return;
    } else {
      getRetrofitOppportunity();
    }
  }, [env, profile]);

  useEffect(() => {
    if (!opportunity || !profile) return;
    trackUser({
      state: opportunity.state,
      fullName: opportunity.fullName,
      email: profile.emailAddress,
      opptyId: opportunity.id,
      prospectId: opportunity.prospectId,
      purchasedThru: opportunity.purchasedThrough,
      utility: opportunity.utility,
    });
  }, [opportunity, profile]);

  return (
    <BatteryContext.Provider
      value={{
        batteryQuantity,
        cleanApplianceData,
        createRetrofitOppty,
        exceededLimit,
        getLoanInfo,
        getNonAllowedAppliances,
        getPricingData,
        initBackupPreferences,
        isCheckingOut,
        isDrawerOpen,
        isMobile,
        loanInfo,
        nonAllowedAppliances,
        opportunity,
        parentOpportunities,
        pricingData,
        pricingSelection,
        profile,
        saveBackupPreferences,
        selectedAppliances,
        setBatteryQuantity,
        setExceededLimit,
        setIsCheckingOut,
        setNonAllowedAppliances,
        setOpportunity,
        setPricingSelection,
        setSelectedAppliances,
        setStep,
        step,
        submitProposal,
        toggleDrawer,
      }}
      {...props}
    />
  );
};

export const useBatteryContext = () => useContext(BatteryContext);
export default BatteryProvider;
