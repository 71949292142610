import React, { useEffect } from 'react';
import { LANDING_PAGE } from '../../constants/mixpanel';

import { useBatteryContext } from '../../context/battery.context';
import mixpanel from '../../mixpanel/mixpanel';
import Cart from '../Cart/Cart';
import Loading from '../Loading/Loading.js';
import ChatBanner from './ChatBanner/ChatBanner';
import Footer from './Footer/Footer';
import Landing from './Landing';
import ModelInfo from './ModelInfo/ModelInfo';
import NextSteps from './NextSteps/NextSteps.tsx';
import PriceComparison from './PriceComparison/PriceComparison';
import ProductComparison from './ProductComparison/ProductComparison';
import StoreFront from './StoreFront/StoreFront';
import Slideshow from './Testimonial/Slideshow';

const LandingStorefront = () => {
  const { isCheckingOut } = useBatteryContext();
  useEffect(() => mixpanel.track(LANDING_PAGE), []);

  return (
    <Landing>
      {isCheckingOut && <Loading />}
      <Cart />
      <StoreFront />
      <ChatBanner />
      <ModelInfo />
      <PriceComparison />
      <ProductComparison />
      <NextSteps />
      <Slideshow />
      <Footer />
    </Landing>
  );
};

export default LandingStorefront;
