import { Loader } from '@sunrun/loader';

import PropTypes from 'prop-types';
import React from 'react';

import { loadingText } from '../../constants/loading';
import useStyles from './Loading.styles';

const FullScreenLoading = ({
    success,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.loader}>
            <Loader active text={loadingText}/>
        </div>
    );
}

FullScreenLoading.propTypes = {
    success: PropTypes.bool,
};

export default FullScreenLoading;