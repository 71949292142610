import PropTypes from 'prop-types';
import React from 'react';
import { useModelInfoStyles } from './ModelInfo.styles';

export default function ModelDetail({ title, details }) {
  const classes = useModelInfoStyles();
  return (
    <dl>
      <dt className={classes.detailTitle}>{title}</dt>
      <dd className={classes.detailText}>{details}</dd>
    </dl>
  );
}

ModelDetail.propTypes = {
  title: PropTypes.string,
  details: PropTypes.string,
};
