import React from 'react';

const ErrorIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 18' width={18} height={18}>
    <path
      fill='#CB2B51'
      d='M.75 15.75h16.5L9 1.5.75 15.75zm9-2.25h-1.5V12h1.5v1.5zm0-3h-1.5v-3h1.5v3z'
    />
  </svg>
);

export default ErrorIcon;
