import { Typography } from '@material-ui/core';
import React from 'react';

import teslaImage from '../../../images/Tesla.png';
import teslaPowerWallCertifiedImage from '../../../images/teslaPowerWallCertified.png';
import { useStyles } from './InfoPage.styles';

interface Props {
  children: React.ReactNode,
  title: string,
}

const InfoPage = ({ children, title }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <Typography className={classes.title} variant='h1'>
          {title}
        </Typography>
        <Typography className={classes.body}>{children}</Typography>
        <img
          alt='Tesla Powerwall certified installer'
          className={classes.teslaPowerWallCertifiedImage}
          src={teslaPowerWallCertifiedImage}
        />
      </div>
      <div>
        <img
          alt='Tesla Powerwall battery'
          className={classes.teslaImage}
          src={teslaImage}
        />
      </div>
    </div>
  );
};

export default InfoPage;
