import PropTypes from 'prop-types';
import React from 'react';

const NavigationDot = ({ selected, onClick }) => (
    <div onClick={onClick}>
        <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: '8px' }}
        >
            <circle cx="4" cy="4" r="4" fill={selected ? "#575766" : "#D5D5E0"} />
        </svg>
    </div>
);

export default NavigationDot;

NavigationDot.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func
};
