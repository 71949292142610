import mixpanel from 'mixpanel-browser';
import { mixpanelKeys } from '../constants/mixpanel';
import getEnv, { Environment } from '../utils/environment';
import { Rollbar } from '../utils/rollbar';

interface MixpanelUser {
  state: string;
  fullName: string;
  email: string;
  opptyId: string;
  prospectId: string;
  purchasedThru: string;
  utility: string;
}

const env: Environment = getEnv(window.location.host);
const MIXPANEL_KEY: string = mixpanelKeys[env];
mixpanel.init(MIXPANEL_KEY);

const trackUser = ({ state, fullName, email, opptyId, prospectId, purchasedThru, utility }: MixpanelUser): void => {
  const person = {
    'Product Offering': 'Battery Retrofit',
    State: state,
    'Customer Name': fullName,
    Email: email,
    'Oppty ID': opptyId,
    'Prospect ID': prospectId,
    'Purchased Thru': purchasedThru,
    'Utility Company': utility,
  };

  try {
    mixpanel.register(person);
    mixpanel.people.set(person);
    return mixpanel.identify(email);
  } catch(err) {
    if (env !== Environment.PROD) {
      console.log('Error while calling Mixpanel function', err);
    }
    Rollbar.error('Error while calling Mixpanel function', err);
  }
};

export default mixpanel;
export { trackUser };