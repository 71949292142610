import { StepConnector, StepLabel, Stepper } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

import { steps, toolTipMap } from '../../../constants/steps';
import { useStepStyles } from '../Cart.styles';

const Steps = ({ step, navigateToStep }) => {
  const classes = useStepStyles();

  const handleSpacePress = (e, index) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
      navigateToStep(index);
    }
  };

  return (
    <div className={classes.stepContainer}>
      <Stepper activeStep={step} className={classes.stepper}>
        {steps.map((stepName, index) => (
          <Tooltip title={toolTipMap[stepName]} key={index}>
            <StepConnector
              onClick={() => navigateToStep(index)}
              onKeyDown={(e) => handleSpacePress(e, index)}
              key={index}
              tabIndex={index === 0 ? 0 : null}
              classes={{
                root: index < step ? classes.stepPrevious : classes.step,
                active: classes.active,
                line: classes.line,
              }}
            />
          </Tooltip>
        ))}
      </Stepper>
      <StepLabel className={classes.label}>
        {`Step ${step + 1} of ${steps.length}`}
      </StepLabel>
    </div>
  );
};

Steps.propTypes = {
  step: PropTypes.number,
  navigateToStep: PropTypes.func,
};

Steps.defaultProps = {
  step: 0,
};

export default Steps;
