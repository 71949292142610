import {
  batteryModels,
  constants,
} from '@sunrun/battery-recommendation-service';
import axios from 'axios';

import { BATTERY_REC_API } from '../constants/baseUrls';
import { getCache, setCache } from './cache';

const battery = batteryModels.get(constants.TESLA);

export const getPricing = async (
  batteryQuantity,
  opportunity,
  env = 'local'
) => {
  if (!opportunity || !opportunity.state || !opportunity.utility) return;

  const { state, utility, salesDivision, salesPartner } = opportunity;

  const inputs = {
    state,
    utility,
    salesDivision,
    salesPartner,
    manufacturer: battery.manufacturer,
    model: battery.model,
    count: batteryQuantity,
  };

  const cacheKey = JSON.stringify(inputs);
  // check cache first
  let batteryPriceCached = getCache(cacheKey);
  if (batteryPriceCached) return batteryPriceCached;

  try {
    const { data } = await axios.post(
      `${BATTERY_REC_API[env]}/v1/pricing`,
      inputs
    );
    const batteryPrice = data.batteryPrices.find(
      (bp) => bp.productType === 'CustomerOwned'
    );
    batteryPrice.version = data.version;

    setCache(cacheKey, batteryPrice);

    return batteryPrice;
  } catch (err) {
    throw new Error(err);
  }
};
