// Colors
export const promptOrange = '#F4B322';
export const successGreen = '#038761';
export const baseBlue = '#003CFF';
export const errorRed = '#F44D45';
export const errorMain = '#CB2B51';
export const lightError = 'rgba(204, 0, 0, 0.1)';

// Grays
export const white = '#FFFFFF';
export const bgGray = '#FCFCFC';
export const secondaryBgGray = '#F5F5FA';
export const secondaryBgGrayLightOpacity = 'rgba(245, 245, 250, 0.5)';
export const secondaryTextGray = '#333333';
export const secondaryTextLightGray = '#C2C2CC';
export const borderGray = '#E6E6E6';
export const lightGray = '#D5D5E0';
export const faintGray = '#C4C4C4';
export const textGray = '#979797';
export const bodyBlack = '#4A4A4A';
export const primaryBlack = '#484848';
export const titleBlack = '#18191F';
export const whiteLightOpacity = 'rgba(255, 255, 255, 0.3)';
export const blackCustomOpacity = (opacity) => `rgba(0, 0, 0, ${opacity})`;
