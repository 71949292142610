import {
  batteryModels,
  constants,
} from '@sunrun/battery-recommendation-service';
import { v4 as uuidv4 } from 'uuid';
import { CASH_DEPOSIT_AMOUNT } from '../constants/pricing';
import { FinancialProductType } from './strings';

const teslaBattery = batteryModels.get(constants.TESLA);

/* According to https://sunrun.jira.com/browse/MARCD-144 specification
Remaining tasks for body
- Verify plan information
*/

export const generateProposal = ({
  batteryQuantity,
  loanInfo,
  opportunity,
  pricingData,
  pricingSelection,
}) => {
  const uuid = uuidv4();

  const { apr, term, dealerFees, vendor } = loanInfo;
  const { fullName, prospectId, salesDivision, salesPartner, state, utility } =
    opportunity;
  const { rule, totalPrice, version } = pricingData;

  const proposal = {
    prospectId,
    correlationId: uuid,
    customerInformation: {
      fullName,
      state,
      utility,
      salesDivision,
      salesPartner,
    },
    products: [
      {
        financialProducts: [],
        servicePackage: 'BASE',
        type: 'STORAGE',
        specification: {
          manufacturer: teslaBattery.manufacturer,
          model: teslaBattery.model,
          count: batteryQuantity,
          type: 'Energy Storage System',
        },
        price: {
          version,
          price: totalPrice.toString(),
          tax: '',
          total: totalPrice.toString(),
          appliedRules: [rule],
        },
      },
    ],
  };

  if (pricingSelection === FinancialProductType.CASH) {
    proposal.products[0].financialProducts.push({
      name: FinancialProductType.CASH,
      amount: (totalPrice - CASH_DEPOSIT_AMOUNT).toString(),
    });
    proposal.products[0].financialProducts.push({
      name: FinancialProductType.DEPOSIT,
      amount: (CASH_DEPOSIT_AMOUNT).toString(),
    });
  } else {
    proposal.products[0].financialProducts.push({
      name: FinancialProductType.LOAN,
      amount: totalPrice.toString(),
      apr,
      term,
      dealerFees,
      financingInstitution: vendor,
    });
  }

  return proposal;
};
