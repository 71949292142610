import { appliances } from '@sunrun/battery-recommendation-service';
import axios from 'axios';
import deepClone from 'lodash.clonedeep';

import { BATTERY_REC_API } from '../constants/baseUrls';

const backupQuestion = (num) => `back up choice ${num}`;
const NUM_BACKUP_CHOICES = 20; // There are 20 back up choice questions

export function appliancesFromPreferences(preferences) {
  const selectedAppliances = [];

  for (let i = 0; i < NUM_BACKUP_CHOICES; i++) {
    const backupChoice = preferences.designPreferences.newDesignPref.find(
      (pref) => pref.question.toLowerCase() === backupQuestion(i + 1)
    );

    if (backupChoice.response && appliances.get(backupChoice.response)) {
      selectedAppliances.push(backupChoice.response);
    }
  }

  return selectedAppliances;
}

export async function getDesignPreferences(prospectId, env = 'local') {
  return axios.get(
    `${BATTERY_REC_API[env]}/v1/preferences?prospectId=${prospectId}&env=${env}`
  );
}

export async function setDesignPreferences(designPreferences, env = 'local') {
  return axios.post(`${BATTERY_REC_API[env]}/v1/preferences`, {
    designPreferences,
    env,
  });
}

export function updatePreferences(prefs, selectedAppliances) {
  const preferences = deepClone(prefs);

  for (let i = 0; i < NUM_BACKUP_CHOICES; i++) {
    const preferenceIndex =
      preferences.designPreferences.newDesignPref.findIndex(
        (pref) => pref.question.toLowerCase() === backupQuestion(i + 1)
      );

    if (preferenceIndex > -1) {
      preferences.designPreferences.newDesignPref[preferenceIndex].response =
        selectedAppliances[i] || '';
    }
  }

  return {
    ProspectId: preferences.ProspectId,
    designPreferences: {
      ...preferences.designPreferences,
    },
  };
}
