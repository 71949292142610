import { ClassNameMap } from '@material-ui/styles';
import React, { useState } from 'react';
import { useNextStepsAccordionStyles } from './NextStepsAccordion.styles';
import NextStepsAccordionItem from './NextStepsAccordionItem';

export default function NextStepsAccordion() {
  const [expanded, setExpanded] = useState<number>(0);
  const classes: ClassNameMap = useNextStepsAccordionStyles();

  const numberOfAccordionItems: number = 4;
  const nextStepsAccordionItems = [];
  for (let i = 0; i < numberOfAccordionItems; i++) {
    nextStepsAccordionItems.push(
      <NextStepsAccordionItem
        itemNumber={i}
        expanded={expanded}
        setExpanded={setExpanded}
        isLastItem={i === numberOfAccordionItems - 1}
      />
    );
  }

  return <div className={classes.container}>{nextStepsAccordionItems}</div>;
}
