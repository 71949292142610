import {
  batteryModels,
  constants,
} from '@sunrun/battery-recommendation-service';
import PropTypes from 'prop-types';
import React from 'react';
import { CHANGED_BATTERY_COUNT } from '../../constants/mixpanel';

import { useBatteryContext } from '../../context/battery.context';
import mixpanel from '../../mixpanel/mixpanel';
import SectionTitle from '../SectionTitle/SectionTitle';
import BatteryCard from './BatteryCard';

// hard-coded for now, since there is only one option
const teslaBattery = batteryModels.get(constants.TESLA);

const BatterySelection = ({ title }) => {
  const { batteryQuantity, pricingData, setBatteryQuantity } =
    useBatteryContext();

  const onBatteryQuantityChange = (newQuantity) => {
    if (newQuantity === batteryQuantity) return;
    mixpanel.track(CHANGED_BATTERY_COUNT);
    setBatteryQuantity(newQuantity);
  };

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <BatteryCard
        model={teslaBattery.model}
        uiDisplayName={teslaBattery.uiDisplayName}
        manufacturer={teslaBattery.manufacturer}
        price={pricingData?.totalPrice}
        quantity={batteryQuantity}
        onClick={onBatteryQuantityChange}
      />
    </>
  );
};

BatterySelection.propTypes = {
  title: PropTypes.string,
};
export default BatterySelection;
