import React from 'react';

const ArrowBack = () => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' width={24} height={24}>
    <path
      stroke='#E96354'
      d='M12 2C6.47715 2 2 6.47715 2 12c0 5.5228 4.47715 10 10 10 5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10z'
    />
    <path stroke='#E96354' d='M12 8l-4 4 4 4M16 12H8' />
  </svg>
);

export default ArrowBack;
