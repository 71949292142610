import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: '390px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
}));

export default useStyles;
