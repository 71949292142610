import { makeStyles } from '@material-ui/core';

import { white, whiteLightOpacity } from '../../../constants/colors';
import { SIZE } from '../../../constants/fonts';
import {
  chevronContainerHeight,
  chatBannerInnerHeight,
  chatBannerPaddingHeight,
  headerHeight,
} from '../../../constants/landing';
import backgroundImage from '../../../images/background.png';

export const useStoreFrontStyles = makeStyles((theme) => ({
  container: {
    border: `3px solid ${whiteLightOpacity}`,
    margin: '0 72px',
    height: `calc(100vh - ${
      chatBannerInnerHeight +
      chatBannerPaddingHeight +
      headerHeight +
      chevronContainerHeight
    }px)`,
    [theme.breakpoints.down('lg')]: {
      margin: '0 64px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 40px',
    },
    [theme.breakpoints.down('xs')]: {
      border: 0,
    },
  },
  image: {
    backgroundImage: `
      url(${backgroundImage})`,
    height: `calc(100vh - ${
      chatBannerInnerHeight + chatBannerPaddingHeight
    }px)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0% 40%',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: '75vh',
      backgroundPosition: '20%',
    },
  },
  chevronContainer: {
    height: chevronContainerHeight,
    margin: '4px 72px 0 72px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      margin: '2px 64px 0 64px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  button: {
    background: 'none',
    border: '0px',
  },
  chevron: {
    cursor: 'pointer',
  },
  title: {
    '&.MuiTypography-h1': {
      color: white,
      fontFeatureSettings: `'salt' 1, 'liga' 0`,
      marginTop: 24,
      textAlign: 'center',
      textShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',

      [theme.breakpoints.up('md')]: {
        marginTop: 40,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 56,
      },
    },
  },
  subtitle: {
    '&.MuiTypography-body1': {
      color: white,
      fontFeatureSettings: `'salt' 1, 'liga' 0`,
      margin: '16px auto 0',
      textAlign: 'center',
      maxWidth: '30em',
      [theme.breakpoints.up('md')]: {
        letterSpacing: '0.01em',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: SIZE.px20,
        maxWidth: '25em',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: SIZE.px28,
        letterSpacing: 0,
      },
    },
  },
}));
