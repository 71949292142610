import { makeStyles } from '@material-ui/core';
import { white } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
    loader: {
        top: 0,
        left: 0,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        zIndex: 1201,
        backgroundColor: white
    }
}));

export default useStyles;
