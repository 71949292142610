import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from './SectionTitle.styles';

const SectionTitle = ({ children, className, variant = 'h2', ...props }) => {
  const classes = useStyles();
  return (
    <Typography className={classNames(classes.title, variant, className)} variant={variant} {...props}>
      {children}
    </Typography>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default SectionTitle;
