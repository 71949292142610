import { makeStyles } from '@material-ui/core';

import {
  baseBlue,
  secondaryBgGray,
  titleBlack,
  bodyBlack,
} from '../../../constants/colors';
import { SIZE, WEIGHT } from '../../../constants/fonts';
import teslaBatteryImage from '../../../images/Tesla.png';

export const useModelInfoStyles = makeStyles((theme) => ({
  container: {
    padding: '24px 32px',
    borderBottom: `8px solid ${secondaryBgGray}`,
    [theme.breakpoints.up('md')]: {
      borderBottom: 0,
      padding: '48px 40px',

      'dl:last-child, dl:last-child dd': {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.up('lg')]: {
      padding: 64,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 138,
    },

    '& dl': {
      marginTop: 0,
    },
  },
  descriptionContainer: {
    alignItems: 'center',
    backgroundImage: `url(${teslaBatteryImage})`,
    backgroundPosition: '90% 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '322px',
    display: 'flex',
    marginBottom: 24,
    [theme.breakpoints.up('md')]: {
      background: 'none',
      marginBottom: 64,
    },

    '& > *': {
      [theme.breakpoints.up('md')]: {
        flex: '0 0 50%',
      },
    },
  },
  description: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 150,
    textAlign: 'right',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('xl')]: {
      alignItems: 'flex-end',
      width: '28%',
    },
  },
  descriptionTitle: {
    '&.MuiTypography-h2': {
      fontWeight: WEIGHT.max,
      color: baseBlue,
      fontSize: SIZE.px32,
      lineHeight: 1.2,
      margin: '0 0 96px',
      textAlign: 'left',
      whiteSpace: 'pre-line',
      [theme.breakpoints.up('md')]: {
        fontSize: SIZE.px40,
        marginBottom: 34,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: SIZE.px48,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: SIZE.px72,
        marginBottom: 40,
        textAlign: 'right',
      },
    },
  },
  descriptionSubtitle: {
    '&.MuiTypography-body1': {
      color: titleBlack,
      marginBottom: 24,
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        lineHeight: 1.78,
        maxWidth: 430,
      },
      [theme.breakpoints.up('xl')]: {
        textAlign: 'right',
      },
    },
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: 960,
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
    },
  },
  detailTitle: {
    color: bodyBlack,
    fontSize: SIZE.px12,
    fontWeight: WEIGHT.bolder,
    letterSpacing: 1,
    lineHeight: 1.5,
    [theme.breakpoints.up('md')]: {
      lineHeight: 1,
    },
  },
  detailText: {
    color: bodyBlack,
    fontSize: SIZE.px14,
    lineHeight: 1.5,
    margin: '10px 0 32px',
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('md')]: {
      lineHeight: 1.43,
    },
  },
  teslaPowerWallCertifiedImage: {
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
    },
  },
  teslaImage: {
    width: '100%',
  },
  teslaImageContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: '50%',
    },
  },
  column: {
    width: '20%',
    [theme.breakpoints.down('lg')]: {
      width: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
