import { makeStyles, Theme } from '@material-ui/core';
import { titleBlack, white } from '../../../constants/colors';
import { SIZE, WEIGHT } from '../../../constants/fonts';
import nextStepsBackground from '../../../images/nextStepsBackground.png';

export const useNextStepsStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundSize: 'cover',
    backgroundImage: `url(${nextStepsBackground})`,
    backgroundRepeat: 'no-repeat',
    height: 537,
    zIndex: 2,
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      height: 600,
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      paddingBottom: 0,
      backgroundImage: 'none',
      backgroundColor: titleBlack,
    },
  },
  text: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      flex: 1,
      padding: '0 10px 0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 40,
      alignItems: 'flex-start',
    },
  },
  title: {
    marginBottom: 50,
    '&.MuiTypography-body1': {
      color: white,
      fontWeight: WEIGHT.bolder,
      fontSize: SIZE.px72,
      textAlign: 'center',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('lg')]: {
        marginBottom: 15,
        fontSize: SIZE.px32,
        marginTop: 20,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 20,
        marginTop: 0,
      },
    },
  },
  subtitle: {
    '&.MuiTypography-body1': {
      color: white,
      fontWeight: WEIGHT.bolder,
      fontSize: SIZE.px18,
      textAlign: 'center',
      maxWidth: 500,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 700,
        fontSize: SIZE.px16,
      },
      [theme.breakpoints.down('xs')]: {
        lineHeight: 1.5,
        fontWeight: WEIGHT.normal,
        textAlign: 'left',
      },
    },
  },
}));
