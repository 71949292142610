import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'semantic-ui-css/semantic.min.css';
import './globals.css';

ReactDOM.render(<App />, document.getElementById('root'));

try {
  const build = require('./.build.json');
  window['BUILD'] = {
    VERSION: build.VERSION,
    BRANCH: build.BRANCH,
    COMMIT: build.COMMIT,
  };
} catch (err) {
  console.debug('Could not create build info!', err);
}
