import { makeStyles, Theme } from '@material-ui/core';
import {
  blackCustomOpacity,
  secondaryBgGrayLightOpacity,
  titleBlack,
  white,
} from '../../../constants/colors';
import { SIZE, WEIGHT } from '../../../constants/fonts';

export const useNextStepsAccordionStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    [theme.breakpoints.down('lg')]: {
      flex: 2.5,
    },
    [theme.breakpoints.down('xs')]: {
      flex: 4,
    },
  },
  summary: {
    '&.MuiTypography-body1': {
      color: white,
      fontWeight: WEIGHT.bolder,
      fontSize: SIZE.px14,
      lineHeight: 1.5,
    },
  },
  details: {
    paddingLeft: 68,
    '&.MuiTypography-body1': {
      color: white,
      fontWeight: WEIGHT.medium,
      fontSize: SIZE.px14,
      lineHeight: 1.5,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: '0 25px',
    },
  },
  listNumber: {
    '&.MuiTypography-body1': {
      color: white,
      fontWeight: WEIGHT.bolder,
      fontSize: SIZE.px14,
      lineHeight: 1.5,
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 25,
      },
    },
    '&::after': {
      display: 'inline-block',
      width: 48,
      content: '""',
      borderTop: '1px solid white',
      margin: '0 6px',
      verticalAlign: 'middle',
      [theme.breakpoints.down('xs')]: {
        width: 15,
      },
    },
  },
  nextStep: {
    border: 'none',
    background: 'none',
    marginTop: 16,
    width: 'fit-content',
    cursor: 'pointer',
    '& > .MuiTypography-body1': {
      paddingLeft: 63,
      color: white,
      fontWeight: WEIGHT.bolder,
      fontSize: SIZE.px12,
      lineHeight: 1.5,
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export const accordionStyles = (theme: Theme) => ({
  root: {
    backgroundColor: blackCustomOpacity(0),
    marginBottom: '8px!important',
    marginTop: '0px!important',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: titleBlack,
      borderTop: `1px solid ${secondaryBgGrayLightOpacity}`,
    },
  },
  content: {
    '&$expanded': {
      margin: '100px 0',
    },
  },
});

export const accordionSummaryStyles = (theme: Theme) => ({
  root: {
    backgroundColor: blackCustomOpacity(0.65),
    minHeight: 56,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: titleBlack,
    },
  },
});

export const accordionDetailsStyles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    backgroundColor: blackCustomOpacity(0.65),
    minHeight: 56,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: titleBlack,
    },
  },
});

export const useExpandedIconStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
