import { makeStyles } from '@material-ui/core';

import { titleBlack } from '../../../constants/colors';

// To ensure that content is not hidden by fixed footer
const footerHeight = 168;
const footerHeightLg = 101;

export const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    flexFlow: 'wrap',
    margin: 32,
    marginBottom: footerHeight,
    [breakpoints.up('md')]: {
      margin: 40,
    },
    [breakpoints.up('lg')]: {
      background: 'none',
      margin: 64,
      marginBottom: footerHeightLg,
    },

    '& > *': {
      flex: '0 0 100%',
      [breakpoints.up('md')]: {
        flex: '0 0 50%',
      },
    },

    // footer
    '& + div': {
      bottom: 0,
      position: 'fixed',
      width: '100%',
    },
  },
  info: {
    [breakpoints.up('md')]: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    [breakpoints.up('xl')]: {
      textAlign: 'right',
    },
  },
  title: {
    '& .MuiTypography-h1': {
      color: titleBlack,
      whiteSpace: 'pre-line',
    },
  },
  body: {
    marginBottom: 24,
    [breakpoints.up('lg')]: {
      marginBottom: 48,
    },
  },
  teslaPowerWallCertifiedImage: {
    display: 'none',
    width: 101,
    [breakpoints.up('md')]: {
      alignSelf: 'flex-end',
      display: 'block',
    },
    [breakpoints.up('lg')]: {
      width: 'auto',
    },
  },
  teslaImage: {
    width: '100%',
    [breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

export default useStyles;
