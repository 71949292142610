import { CircularProgress, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { getPriceText } from '../../utils/strings';

import Button from '../Button/Button';
import Card from '../Card/Card';
import SectionTitle from '../SectionTitle/SectionTitle';
import useStyles from './BatterySelection.styles';

const items = [1, 2, 3];

const BatteryCard = ({ uiDisplayName, onClick, price, quantity }) => {
  const classes = useStyles();
  const priceText = getPriceText(price);
  return (
    <>
      <SectionTitle variant='h3'>{uiDisplayName}</SectionTitle>
      <Card className={classes.container} variant='selected'>
        {price ? (
          <>
            <div className={classNames(classes.buttonContainer)}>
              {items.map((number) => (
                <Button
                  className={quantity === number ? classes.buttonSelected : ''}
                  key={number}
                  onClick={() => onClick(number)}
                  variant='rounded'
                  data-testid='quantityButton'
                >
                  {number.toString()}
                </Button>
              ))}
            </div>
            <div className={classes.priceContainer}>
              <Typography className={classes.priceText}>{priceText}</Typography>
              <Typography className={classes.priceHelperText}>
                Installed Price
              </Typography>
            </div>
          </>
        ) : (
          <CircularProgress className={classes.loading} size={40} />
        )}
      </Card>
    </>
  );
};

BatteryCard.propTypes = {
  uiDisplayName: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.number,
  quantity: PropTypes.number,
};

export default BatteryCard;
