import Typography from '@material-ui/core/Typography';
import { appliances } from '@sunrun/battery-recommendation-service';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useBatteryContext } from '../../context/battery.context';
import { sortAppliances } from '../../utils/appliances';
import SectionTitle from '../SectionTitle/SectionTitle';
import useStyles from './Appliances.styles';
import ApplianceSelection from './ApplianceSelection';

const applianceList = sortAppliances(appliances, true);

const Appliances = ({ onBackStep, handleError, title }) => {
  const {
    batteryQuantity,
    getNonAllowedAppliances,
    nonAllowedAppliances,
    selectedAppliances,
    setSelectedAppliances,
  } = useBatteryContext();

  const classes = useStyles();

  useEffect(() => {
    const applianceData = getNonAllowedAppliances();
    handleError(applianceData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppliances]);

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <Typography className={classes.applianceText}>
        In the event of an outage, what would matter most to you? Your battery
        will work to back up as much as your selected system can handle.
      </Typography>
      <ApplianceSelection
        appliances={applianceList}
        batteryQuantity={batteryQuantity}
        nonAllowedAppliances={nonAllowedAppliances}
        onClick={setSelectedAppliances}
        onClickBack={onBackStep}
        selectedAppliances={selectedAppliances}
      />
    </>
  );
};

Appliances.propTypes = {
  onBackStep: PropTypes.func,
  handleError: PropTypes.func,
  title: PropTypes.string,
};

export default Appliances;
