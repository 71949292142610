import { makeStyles } from '@material-ui/core';

import { headerHeight } from '../../../constants/landing';

export const useHeaderStyles = makeStyles((theme) => ({
  cartIcon: {
    marginLeft: 10,
  },
  logo: {
    height: '19px',
    [theme.breakpoints.down('xs')]: {
      height: '14px',
    },
  },
  header: {
    display: 'flex',
    height: headerHeight,
    alignItems: 'center',
    margin: '0 72px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      margin: '0 64px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 40px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 32px',
    },
  },
}));
