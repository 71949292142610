export enum Environment {
  PROD = 'prod',
  MAGSTG = 'majstg',
  DEVMAJ = 'devmaj',
  LOCAL = 'local'
}

export default function getEnv(host: string) {
  switch (host) {
    case 'battery-selector.sunrun.com':
      return Environment.PROD;
    case 'majstg-battery-selector.sunrun.com':
      return Environment.MAGSTG;
    case 'devmaj-battery-selector.sunrundev.com':
      return Environment.DEVMAJ;
    default:
      return Environment.LOCAL;
  }
}

export const getQueryParam = (param: string): string | null => {
  return new URLSearchParams(window.location.search).get(param);
};
