import { ShoppingCartOutlined } from '@material-ui/icons';
import React from 'react';

import { useBatteryContext } from '../../../context/battery.context';
import Logo from '../../../images/logo.svg';
import Button from '../../Button/Button';
import { useHeaderStyles } from './Header.styles';

const Header = () => {
  const classes = useHeaderStyles();
  const { batteryQuantity, pricingSelection, toggleDrawer, isDrawerOpen } =
    useBatteryContext();

  return (
    <div className={classes.header}>
      <img src={Logo} className={classes.logo} alt='Sunrun Logo' />
      <Button
        onClick={!isDrawerOpen ? toggleDrawer('Header') : null}
        variant='secondary'
      >
        {pricingSelection ? batteryQuantity : 'Order Now'}
        {pricingSelection && (
          <ShoppingCartOutlined className={classes.cartIcon} />
        )}
      </Button>
    </div>
  );
};

export default Header;
