import { createTheme } from '@material-ui/core';
import { bodyBlack, white } from './colors';
import { SIZE, WEIGHT } from './fonts';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1060,
      xl: 1440,
    },
  },
});

export const THEME = ({ breakpoints }) => ({
  background: white,

  '& *': {
    fontFamily: 'Inter, sans-serif',
    '@supports (font-variation-settings: "wdth" 115)': {
      fontFamily: 'InterVariable, sans-serif',
    },
  },
  '& h1': {
    fontSize: SIZE.px32,
    fontWeight: WEIGHT.max,
    letterSpacing: 0,
    lineHeight: 1.21,
    margin: '16px 0',
    [breakpoints.up('md')]: {
      fontSize: SIZE.px40,
    },
    [breakpoints.up('lg')]: {
      fontSize: SIZE.px48,
    },
    [breakpoints.up('xl')]: {
      fontSize: SIZE.px72,
    },
  },
  '& h2': {
    fontSize: SIZE.px24,
    fontWeight: WEIGHT.normal,
    letterSpacing: 0,
    lineHeight: 1.25,
    margin: '.666rem 0',
  },
  '& h3': {
    fontSize: SIZE.px16,
    fontWeight: WEIGHT.medium,
    letterSpacing: 0,
    lineHeight: 1.25,
    margin: '.666rem 0',
  },
  '& p': {
    color: bodyBlack,
    lineHeight: 1.5,
  },
  '& p, & span': {
    fontSize: SIZE.px16,
    fontWeight: WEIGHT.normal,
    letterSpacing: 0,

    [breakpoints.up('md')]: {
      fontSize: SIZE.px18,
    },
  },
  '& .MuiTypography-body2': {
    fontWeight: WEIGHT.light,
  },
  '& strong': {
    color: bodyBlack,
    fontWeight: WEIGHT.medium,
  },
  '& a': {
    color: bodyBlack,
    textDecoration: 'underline',
  },
});

export const drawerOpenTheme = ({ breakpoints }) => ({
  background: white,

  '& *': {
    fontFamily: 'Inter, sans-serif',
    '@supports (font-variation-settings: "wdth" 115)': {
      fontFamily: 'InterVariable, sans-serif',
    },
  },
  '& h1': {
    fontSize: SIZE.px24,
    fontWeight: WEIGHT.max,
    lineHeight: 1.21,
    margin: '16px 0',
    [breakpoints.up('md')]: {
      fontSize: SIZE.px32,
    },
    [breakpoints.up('lg')]: {
      fontSize: SIZE.px40,
    },
    [breakpoints.up('xl')]: {
      fontSize: SIZE.px56,
    },
  },
  '& h2': {
    fontSize: SIZE.px24,
    fontWeight: WEIGHT.normal,
    lineHeight: 1.25,
    margin: '.666rem 0',
  },
  '& h3': {
    fontSize: SIZE.px16,
    fontWeight: WEIGHT.medium,
    lineHeight: 1.25,
    margin: '.666rem 0',
  },
  '& p': {
    color: bodyBlack,
    lineHeight: 1.5,
  },
  '& p, & span': {
    fontSize: SIZE.px14,
    fontWeight: WEIGHT.normal,

    [breakpoints.up('md')]: {
      fontSize: SIZE.px14,
    },
  },
  '& .MuiTypography-body2': {
    fontWeight: WEIGHT.light,
  },
  '& strong': {
    color: bodyBlack,
    fontWeight: WEIGHT.medium,
  },
  '& a': {
    color: bodyBlack,
    textDecoration: 'underline',
  },
});
