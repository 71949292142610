import { appliances } from '@sunrun/battery-recommendation-service';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { batteryQuantityText } from '../../../constants/landing';
import applianceCheckmark from '../../../images/ApplianceCheckmark.svg';
import applianceX from '../../../images/ApplianceX.svg';
import { sortAppliances } from '../../../utils/appliances';
import { useProductComparisonStyles } from './ProductComparison.styles';

export default function ComparisonList({
  title,
  summary,
  numberOfAppliances,
  noBorder,
}) {
  const classes = useProductComparisonStyles();
  const backupAppliances = sortAppliances(appliances);

  return (
    <dl
      className={classes.comparisonList}
      style={{ borderLeft: noBorder && 'none' }}
    >
      <dt className={classes.comparisonListSummary}>
        <span className={classes.comparisonListTitle}>{title}</span>
        {summary}
      </dt>
      {backupAppliances.map(({ name, batteryMinimum }, index) => {
        return (
          <dd
            className={classnames(classes.applianceText, {
              [classes.selectedApplianceText]: index < numberOfAppliances,
            })}
            key={name}
          >
            {index < numberOfAppliances ? (
              <img
                className={classes.applianceIcon}
                src={applianceCheckmark}
                alt='Checkmark'
              />
            ) : (
              <img className={classes.applianceIcon} src={applianceX} alt='X' />
            )}
            {name} {batteryQuantityText[batteryMinimum]}
          </dd>
        );
      })}
    </dl>
  );
}

ComparisonList.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string,
  numberOfAppliances: PropTypes.number,
  noBorder: PropTypes.bool,
};
