import { makeStyles } from '@material-ui/core';
import { baseBlue, errorRed, faintGray } from '../../constants/colors';
import { SIZE } from '../../constants/fonts';

export const useDialogStyles = makeStyles(({breakpoints}) => ({
  dialog: {
    '& .MuiDialog-paper': {
      margin: SIZE.px8,
    },
  },
  error: {
    '&.MuiTypography-body1': {
      color: errorRed,
      textAlign: 'right',
    }
  },
  info: {
    '&.MuiTypography-body1': {
      fontSize: SIZE.px18,
      marginBottom: SIZE.px20,

      [breakpoints.up('md')]: {
        fontSize: SIZE.px24,
      },
    },
  },
  legend: {
    marginBottom: SIZE.px8,
  },
  loading: {
    marginRight: SIZE.px8,
  },
  radio: {
    border: `1px solid ${faintGray}`,
    margin: `0 0 ${SIZE.px12}`,
    padding: SIZE.px8,

    '&.selected': {
      border: `2px solid ${baseBlue}`
    }
  },
}));
