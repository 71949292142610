export const ChatHeading = 'Generate, store, and manage energy on your terms.';
export const ChatHeadingTitle = 'POWER THROUGH';
export const ChatButtonText = 'Chat now';
export const HeroTitle = 'Power through with Powerwall';
export const HeroSubtitle =
  'Existing customers in California are now eligible to purchase a Tesla Powerwall battery add-on.';
export const ModelDescriptionTitle = `Powerful.
Predictable.
Powerwall.`;
export const ModelDescriptionSubtitle = `You don't have to expect the unexpected. With battery backup, you can store excess energy you generate to use at night or during outages. You can even use it when utility rates increase to help keep your bills low. Stay in control with clean, solar energy when you need it—rain or shine.`;
export const EnergyCapacityTitle = 'ENERGY CAPACITY';
export const OnGridPowerTitle = 'ON GRID POWER';
export const BackupPowerTitle = 'BACKUP POWER';
export const SizeAndWeightTitle = 'SIZE AND WEIGHT';
export const InstallationTitle = 'INSTALLATION';
export const WarrantyTitle = 'WARRANTY';
export const EngergyCapacityDetail = '13.5 kWh';
export const OnGridPowerDetail = '5.8 kw continuous';
export const BackupPowerDetail = `10 kW peak
106A LRA start
Seamless backup transition
`;
export const SizeAndWeightDetail = `L x W x D
45.3 in x 29.6 in x 5.75 in
251.3 lbs
`;
export const InstallationDetail = `Floor and wall mounted
Indoor and outdoor
Up to 10 circuits
-4F to 122F
Water and dust resistance
`;
export const WarrantyDetail = `Sunrun offers a 10 year limited workmanship warranty which protects you against material defects associated with installation.

Tesla provides you a 10 year limited warranty on the Powerwall battery.
`;
export const OneBatteryTitle = `1 Battery`;
export const OneBatterySummary = `Backup up to 5 items, of your necessities`;
export const TwoBatteryTitle = `2 Batteries`;
export const TwoBatterySummary = `Backup up to 10 items, incl. A/C and Tesla EV`;
export const ThreeBatteryTitle = `3 Batteries`;
export const ThreeBatterySummary = `Backup up to 15 items, for most full home needs`;
export const ComparisonTitle = 'Power what you need';
export const ComparisonSubtitle = `even when the sun isn't shining`;
export const batteryQuantityText = {
  1: '',
  2: '(2+ batteries)',
  3: '(3 batteries)',
};

export const priceComparisonSummary = {
  1: 'Back up the basics',
  2: 'Keep my home comfortable',
  3: 'Power through anything',
};
export const installedPriceLabel = 'Installed Price';
export const savingsLabel = '26% Fed Tax Credit*';
export const totalPriceLabel = 'After incentives*';
export const orderButtonText = 'ORDER NOW';
export const priceComparisonDisclaimer = `* Please consult your tax advisor to determine the
  applicability of this credit to your tax situation. Sunrun does not provide tax advice or
  guarantee that you can take the tax credit. As a result of battery storage installation
  your utility may change your energy plan, fees, and/or the amount the utility purchases
  excess energy for Net Energy Metering. Contact your utility for any changes they require.`;
export const priceComparisonTitle = 'Choose your backup';
export const NEXT_STEPS_TITLE = `What's next`;
export const NEXT_STEPS_SUBTITLE = `We’re here to guide you the rest of the way. Our team will take care
of all the necessary steps of installing your battery.`;
export const NEXT_STEPS_ACCORDION_SUMMARIES: { [key: number]: string } = {
  0: `SITE SURVEY`,
  1: `DESIGN APPROVAL`,
  2: `INSTALLATION`,
  3: `SYSTEM GO LIVE`,
};
export const NEXT_STEP_ACCORDION_DETAILS: { [key: number]: string } = {
  0: `You’ll soon receive an email confirming a visit from a Sunrun expert
  technician. We’ll confirm your solar storage system specifications,
  verify measurements, and validate your home’s primary needs during
  an outage.`,
  1: `Any necessary adjustments to your original battery setup will be
  emailed to you for final approval, and upon confirmation we’ll
  submit your design to the city for permitting. This process can take
  up to eight weeks, depending on your local government.`,
  2: `It's time for installation! Our team will preview an overview of the
  process before beginning work on your home. Installation times vary
  but typically take 4-8 hours.`,
  3: `Your local utility will perform final inspection and provide
  Permission to Operate. Time may vary depending on your local
  utility. Your battery will then be activated.`,
};

export const testimonialText = {
  1: {
    'quote': "The reason I got the battery is, I wanted to have a source of stored energy that I could rely on during brownouts and blackouts. But also, I can now use the battery to power my house at night. I would love to get to the point where I am 100% self reliant and the battery would help me do that.",
    'name': "T Carrona",
    'custSince': "Sunrun customers since 2019"
  },
  2: {
    'quote': "I'm very concerned for the environment and leaving a planet for our children and great grandchildren. I’d like them to be able to grow up and have a good life. And I like the idea that as we’re getting older, and we have more needs, that we have our battery backup.",
    'name': "The Chowdhurys",
    'custSince': "Sunrun customers since 2020"
  },
  3: {
    'quote': "We get a lot of wildfires causing intermittent shutdowns of power and that motivated us to get the solar plus battery backup. We had an outage recently because a tree fell on a power line. But we didn’t even notice the power went out! We were mid summer and the AC was on, everything was working. The whole process took two day, less than two days, in and out. They really did a great job.",
    'name': "K Bains",
    'custSince': "Sunrun customer since 2020"
  }
}
// Style constants

export const headerHeight = 70;
export const chevronContainerHeight = 78;
export const chatBannerInnerHeight = 45;
export const chatBannerPaddingHeight = 68;
