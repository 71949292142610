import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import {
  backupSubtitle,
  batteryQuantityText,
} from '../../constants/appliances';
import { applianceOverload } from '../../constants/errors';
import { TOGGLED_BACKUP_PREF } from '../../constants/mixpanel';
import ArrowBack from '../../images/arrowBack';
import ErrorIcon from '../../images/ErrorIcon';
import mixpanel from '../../mixpanel/mixpanel';
import Button from '../Button/Button';
import CardComponent from '../Card/Card';
import SectionTitle from '../SectionTitle/SectionTitle';
import useStyles from './Appliances.styles';

const ApplianceSelection = ({
  appliances,
  batteryQuantity,
  exceededLimit,
  nonAllowedAppliances,
  onClick,
  onClickBack,
  selectedAppliances,
}) => {
  const classes = useStyles();

  const onCardClick = useCallback(
    (value) => {
      mixpanel.track(TOGGLED_BACKUP_PREF);
      if (selectedAppliances.includes(value)) {
        const newList = selectedAppliances.filter((item) => item !== value);
        return onClick(newList);
      }
      return onClick([...selectedAppliances, value]);
    },
    [onClick, selectedAppliances]
  );

  const handleSpacePress = (e, name) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
      onCardClick(name);
    }
  };

  return (
    <div className={classes.selectionContainer}>
      <SectionTitle variant='h3'>Backup selections</SectionTitle>
      <Typography className={classes.backupSubtitle}>
        {backupSubtitle[batteryQuantity]}
      </Typography>
      {appliances.map(({ name, batteryMinimum }) => {
        const isSelected = selectedAppliances.includes(name);
        const isNotAllowed = isSelected && nonAllowedAppliances.includes(name);
        const hasError =
          isSelected && (nonAllowedAppliances.includes(name) || exceededLimit);
        return (
          <div key={name}>
            <CardComponent
              className={classNames(
                classes.card,
                { selected: isSelected },
                { hasError: hasError }
              )}
              onClick={() => onCardClick(name)}
              onKeyDown={(e) => handleSpacePress(e, name)}
              data-testid='applianceCard'
            >
              <div>
                <Typography>{name}</Typography>
                <Typography className={classes.applianceHelper}>
                  {batteryQuantityText[batteryMinimum]}
                </Typography>
              </div>
              {isNotAllowed && <ErrorIcon />}
            </CardComponent>
            {isNotAllowed && (
              <div className={classes.errorContainer}>
                <Typography>
                  {applianceOverload(batteryMinimum - batteryQuantity)}
                </Typography>
                <Button
                  variant='error'
                  onClick={onClickBack}
                  data-testid='errorButton'
                >
                  <ArrowBack /> Change Battery Selection
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

ApplianceSelection.propTypes = {
  appliances: PropTypes.array,
  batteryQuantity: PropTypes.number,
  exceededLimit: PropTypes.number,
  nonAllowedAppliances: PropTypes.array,
  onClick: PropTypes.func,
  onClickBack: PropTypes.func,
  selectedAppliances: PropTypes.array,
};

export default ApplianceSelection;
