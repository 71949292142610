import { makeStyles } from '@material-ui/core';
import { baseBlue, textGray, titleBlack } from '../../constants/colors';
import { SIZE } from '../../constants/fonts';

const useStyles = makeStyles(() => ({
  card: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '12px 0 12px 16px !important',
    padding: `${SIZE.px16} !important`,
    '& .selected': {
      borderColor: baseBlue,
    },
    '&:focus': {
      boxShadow: '4px 2px 12px 5px rgba(196,196,196,0.4)',
    },
  },
  errorContainer: {
    width: '85%',
    margin: '12px auto',
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      paddingBottom: 5,
    },
    '& svg': {
      paddingRight: 10,
    },
  },
  selectionContainer: {
    margin: '35px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  backupSubtitle: {
    fontSize: SIZE.px12,
    lineHeight: 1.33,
    color: titleBlack,
    margin: '10px 0 10px 16px',
    fontStyle: 'italic',
  },
  applianceHelper: {
    fontSize: SIZE.px12,
    lineHeight: 1.25,
    color: textGray,
  },
  applianceText: {
    marginLeft: SIZE.px16,
  },
}));

export default useStyles;
