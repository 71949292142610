import { Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import {
  emptyAppliances,
  emptyFinancing,
  limitErrors,
  failedProposalCreation,
} from '../../constants/errors';
import {
  PROCEEDED_TO_BACKUP_PREFS,
  PROCEEDED_TO_CHECKOUT,
  STEP_BACK_CART,
} from '../../constants/mixpanel';
import { steps } from '../../constants/steps';
import { useBatteryContext } from '../../context/battery.context';
import mixpanel from '../../mixpanel/mixpanel';
import { Rollbar } from '../../utils/rollbar';
import { getFooterTotal } from '../../utils/strings';
import Appliances from '../Appliances/Appliances';
import BatterySelection from '../BatterySelection/BatterySelection';
import PricingInfo from '../Pricing/PricingInfo';
import ResponsiveDrawer from '../ResponsiveDrawer/ResponsiveDrawer';
import useStyles from './Cart.styles';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Steps from './Steps/Steps';

const Cart = () => {
  const [error, setError] = useState(false);
  const {
    batteryQuantity,
    cleanApplianceData,
    getLoanInfo,
    getNonAllowedAppliances,
    getPricingData,
    initBackupPreferences,
    loanInfo,
    opportunity,
    pricingSelection,
    saveBackupPreferences,
    selectedAppliances,
    setIsCheckingOut,
    step,
    setStep,
    submitProposal,
    nonAllowedAppliances,
  } = useBatteryContext();
  const classes = useStyles();

  const stepName = steps[step];

  const checkout = useCallback(async () => {
    const applianceData = getNonAllowedAppliances();
    if (applianceData.exceededLimit) {
      return setError(limitErrors[batteryQuantity]);
    }

    if (applianceData.nonAllowedAppliances.length) {
      return;
    }

    setIsCheckingOut(true);

    try {
      await saveBackupPreferences();
      mixpanel.track(PROCEEDED_TO_CHECKOUT);
      return await submitProposal();
    } catch (err) {
      Rollbar.error('Error tring to submit proposal', err);
      setIsCheckingOut(false);
      return setError(failedProposalCreation);
    }
  }, [
    batteryQuantity,
    getNonAllowedAppliances,
    saveBackupPreferences,
    setError,
    setIsCheckingOut,
    submitProposal,
  ]);

  const navigateToStep = useCallback(
    (newStep) => {
      // can only go back steps, use button to go forward
      if (step < newStep) return;

      mixpanel.track(STEP_BACK_CART);
      if (stepName === 'Backup') cleanApplianceData();

      setStep(newStep);
    },
    [setStep, cleanApplianceData, step, stepName]
  );

  const onStepNext = useCallback(async () => {
    setError(null);

    if (stepName === 'Backup') return checkout();

    mixpanel.track(PROCEEDED_TO_BACKUP_PREFS, {
      batteryQuantity: batteryQuantity || 'Error: None Selected',
      pricingSelection: pricingSelection || 'No Pricing Option Selected',
    });

    if (stepName === 'Battery' && !pricingSelection) {
      return setError(emptyFinancing);
    }
    setStep(step + 1);
  }, [
    pricingSelection,
    setStep,
    step,
    setError,
    checkout,
    stepName,
    batteryQuantity,
  ]);

  const onStepBack = useCallback(() => {
    cleanApplianceData();
    setStep(step - 1);
  }, [cleanApplianceData, setStep, step]);

  const handleError = (applianceData) => {
    if (applianceData.exceededLimit) {
      setError(limitErrors[batteryQuantity]);
    } else {
      setError(null);
    }
  };

  useEffect(() => {
    if (error && pricingSelection && stepName === 'Battery') {
      setError(null);
    }
    if (!selectedAppliances.length && stepName === 'Backup') {
      setError(null);
      cleanApplianceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, pricingSelection, selectedAppliances, setError, step]);

  useEffect(() => {
    if (!batteryQuantity || !opportunity) return;
    getPricingData(batteryQuantity, opportunity);
  }, [batteryQuantity, opportunity]); // eslint-disable-line react-hooks/exhaustive-deps
  // do not include getPricingData in dependency array, or it will infinitely call getPricingData

  useEffect(() => {
    if (loanInfo || !opportunity) return;
    getLoanInfo(opportunity);
  }, [loanInfo, opportunity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!opportunity) return;
    initBackupPreferences(opportunity.prospectId);
  }, [opportunity]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEmptyAppliancesOnCheckout =
    !selectedAppliances.length && stepName === 'Backup';
  const isNonAllowedAppliancesOnCheckout =
    !!nonAllowedAppliances.length && stepName === 'Backup';

  return (
    <ResponsiveDrawer>
      <div className={classes.container}>
        <div className={classes.cartStepContainer}>
          <Header title='Battery Purchase' />
          <Steps step={step} navigateToStep={navigateToStep} />
        </div>
        {stepName === 'Battery' && (
          <>
            <BatterySelection title='Battery Selection' />
            <PricingInfo title='Financing' />
          </>
        )}
        {stepName === 'Backup' && (
          <Appliances
            title='Backup Preferences'
            onBackStep={onStepBack}
            handleError={handleError}
          />
        )}
      </div>
      {error && <Typography className={classes.error}>{error}</Typography>}
      {isEmptyAppliancesOnCheckout && (
        <Typography className={classes.emptyAppliances}>
          {emptyAppliances}
        </Typography>
      )}
      <Footer
        onClick={onStepNext}
        price={getFooterTotal(pricingSelection)}
        step={step}
        disabled={
          isEmptyAppliancesOnCheckout ||
          isNonAllowedAppliancesOnCheckout ||
          error
        }
      />
    </ResponsiveDrawer>
  );
};

export default Cart;
