import { CASH_DEPOSIT_AMOUNT } from '../constants/pricing';

export const getPriceText = (price: number): string => {
  if (!price) return '';

  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  }).replace(/\.00/, '');
};

export enum FinancialProductType {
  CASH = 'CASH',
  DEPOSIT = 'DEPOSIT',
  LOAN = 'LOAN',
}

export const getFooterTotal = (pricingSelection: FinancialProductType): string =>
  pricingSelection 
    ? (pricingSelection === FinancialProductType.LOAN ? '$0' : `$${CASH_DEPOSIT_AMOUNT}`)
    : '--';
