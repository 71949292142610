import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { footerButtonText } from '../../../constants/steps';
import Button from '../../Button/Button';
import { useFooterStyles } from '../Cart.styles';

const Footer = ({ disabled, onClick, price, step }) => {
  const classes = useFooterStyles();
  return (
    <div className={classes.footerContainer}>
      <div>
        <Typography className={classes.priceLabel}>Due Today</Typography>
        <Typography className={classes.price}>{price}</Typography>
      </div>
      <Button onClick={onClick} disabled={disabled} className={classes.button}>
        {footerButtonText[step]}
      </Button>
    </div>
  );
};

Footer.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  price: PropTypes.string,
  step: PropTypes.number,
};

export default Footer;
