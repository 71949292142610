export const BATTERY_REC_API = {
  local: 'https://k1roh08eo2.execute-api.us-west-2.amazonaws.com/prod',
  devmaj: 'https://k1roh08eo2.execute-api.us-west-2.amazonaws.com/prod',
  majstg: 'https://m4nilxav0c.execute-api.us-west-2.amazonaws.com/prod',
  prod: 'https://8o9ydoft72.execute-api.us-west-2.amazonaws.com/prod',
};

export const SPLAT_URL = {
  local: 'https://local-go.sunrundev.com:8080',
  devmaj: 'https://devmaj-go.sunrundev.com',
  majstg: 'https://majstg-go.sunrun.com',
  prod: 'https://go.sunrun.com',
};
