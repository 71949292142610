import { makeStyles } from '@material-ui/core';
import {
  baseBlue,
  primaryBlack,
  textGray,
  titleBlack,
} from '../../constants/colors';
import { SIZE, WEIGHT } from '../../constants/fonts';

const useStyles = makeStyles((theme) => ({
  loadingText: {
    '&.MuiTypography-root': {
      color: titleBlack,
      fontSize: SIZE.px16,
      lineHeight: 1.5,
    },
  },
  card: {
    cursor: 'pointer',
    marginLeft: SIZE.px16,
    '&:focus': {
      boxShadow: '4px 2px 12px 5px rgba(196,196,196,0.4)',
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  priceContainer: {
    textAlign: 'right',
  },
  text: {
    '&.MuiTypography-body1': {
      color: primaryBlack,
      fontSize: SIZE.px16,
    },
  },
  secondaryText: {
    '&.MuiTypography-body1': {
      color: textGray,
      fontSize: SIZE.px12,
    },
  },
  underline: {
    textDecoration: 'underline',
    width: 'fit-content',
  },
  closeText: {
    color: baseBlue,
    marginBottom: 20,
    fontSize: SIZE.px12,
  },
  priceText: {
    '&.MuiTypography-body1': {
      fontSize: SIZE.px24,
      fontWeight: WEIGHT.bold,
      color: titleBlack,
      lineHeight: 1.25,
    },
  },
  selected: {
    '&.MuiTypography-body1': {
      color: baseBlue,
    },
  },
  loading: {
    color: baseBlue,
    margin: '0 auto',
  },
}));

export default useStyles;
