import React, { useEffect } from 'react';

import { NoStateTitle, NoStateBody, NoStateBody2 } from '../../constants/info';
import { NOS_PAGE } from '../../constants/mixpanel';
import mixpanel from '../../mixpanel/mixpanel';
import Footer from './Footer/Footer';
import InfoPage from './InfoPage/InfoPage.tsx';
import Landing from './Landing';

const LandingNoState = () => {
  useEffect(() => mixpanel.track(NOS_PAGE), []);

  return (
    <Landing>
      <InfoPage title={NoStateTitle}>
        {NoStateBody}<br />
        {NoStateBody2}
      </InfoPage>
      <Footer />
    </Landing>
  );
};

export default LandingNoState;
