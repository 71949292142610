import { Environment } from "../utils/environment";

export type MixpanelKeys = {
  [index in Environment]: string;
}

export const mixpanelKeys: MixpanelKeys = {
  [Environment.LOCAL]: '5a178955f49947b3b86f18ed4f99e495',
  [Environment.DEVMAJ]: '5a178955f49947b3b86f18ed4f99e495',
  [Environment.MAGSTG]: '5a178955f49947b3b86f18ed4f99e495',
  [Environment.PROD]: '1256e56363a5760a3ba1404947fdd808',
};

// Events
export const TOGGLED_CART = 'Toggled Cart View';
export const LANDING_PAGE = 'Visited Retrofit Landing Page';
export const OOM_PAGE = 'Visited Out of Market Page';
export const NOS_PAGE = 'Visited No State Page';
export const ADDED_TO_CART = 'Added to the Cart'
export const LAUNCHED_CHAT = 'Launched Chat';
export const CHANGED_BATTERY_COUNT = 'Changed Battery Count';
export const SELECTED_FINANCING = 'Selected Financing';
export const PROCEEDED_TO_BACKUP_PREFS = 'Proceeded to Backup Preferences';
export const TOGGLED_BACKUP_PREF = 'Toggled Backup Preference';
export const STEP_BACK_CART = 'Stepped Back in Cart';
export const PROCEEDED_TO_CHECKOUT = 'Proceeded to Checkout';
export const ERROR_MISSING_STATE = 'Could not identify user\'s state of residence';
export const ERROR_NO_OPPTY = 'Could not retrieve/create opportunity';

// Event Text
export const financingOptionText = {
  'CASH': 'Customer Owned - Full Upfront',
  'LOAN': 'Customer Owned - Bank Financed'
}