import { Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { HeroSubtitle, HeroTitle } from '../../../constants/landing';
import chevronsDown from '../../../images/chevrons-down.svg';
import Header from '../Header/Header';
import { useStoreFrontStyles } from './StoreFront.styles';

const StoreFront = () => {
  const classes = useStoreFrontStyles();
  const storeFrontRef = useRef();
  const scrollDown = () => {
    storeFrontRef.current.nextElementSibling.nextElementSibling.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div ref={storeFrontRef} className={classes.image}>
      <Header />
      <div className={classes.container}>
        <Typography className={classes.title} variant='h1'>
          {HeroTitle}
        </Typography>
        <Typography className={classes.subtitle}>{HeroSubtitle}</Typography>
      </div>
      <div className={classes.chevronContainer}>
        <button className={classes.button} onClick={scrollDown}>
          <img
            alt='Scroll down'
            src={chevronsDown}
            className={classes.chevron}
          />
        </button>
      </div>
    </div>
  );
};

export default StoreFront;
