import { makeStyles } from '@material-ui/core';

import { white, baseBlue } from '../../../constants/colors';
import { WEIGHT } from '../../../constants/fonts';
import { chatBannerPaddingHeight } from '../../../constants/landing';

export const useChatBannerStyles = makeStyles((theme) => ({
  banner: {
    background: baseBlue,
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    minHeight: '25vh',
    padding: 32,
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexFlow: 'row',
      minHeight: 'unset',
      padding: '32px 40px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '34px 72px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: `${chatBannerPaddingHeight / 2}px 72px`,
    },
  },
  heading: {
    '&.MuiTypography-body1': {
      color: white,
      textTransform: 'uppercase',
      letterSpacing: 1,
      marginRight: 40,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        lineHeight: 1.625,
        margin: '0px auto',
        textAlign: 'center',
      },
    },
  },
  headingTitle: {
    '&.MuiTypography-root': {
      color: white,
      fontWeight: WEIGHT.bold,
      letterSpacing: 1,
      marginRight: 15,
      textTransform: 'uppercase',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
  },
  horizontalLine: {
    width: '100px',
    height: '50%',
    borderBottom: '1px solid white',
    marginRight: '15px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  button: {
    border: `1px solid ${white}`,
    borderRadius: '0',
    margin: '16px auto',
    padding: '8px 24px',
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: '8px 40px',
    },
  },
  buttonLabel: {
    '&.MuiButton-label': {
      color: white,
      fontWeight: WEIGHT.medium,
      letterSpacing: 1,
      lineHeight: 1.625,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('md')]: {
        lineHeight: 1.5,
      },
      [theme.breakpoints.up('lg')]: {
        lineHeight: 1.4444,
      },
      [theme.breakpoints.up('xl')]: {
        fontWeight: WEIGHT.bolder,
        lineHeight: 1.78,
      },
    },
  },
}));
