import { makeStyles } from '@material-ui/core';
import { baseBlue, borderGray, white, errorRed } from '../../constants/colors';
import { SIZE, WEIGHT } from '../../constants/fonts';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 16px 16px 0',
    height: '100vh',
    overflowY: 'scroll',
  },
  error: {
    padding: 16,
    color: errorRed,
  },
  emptyAppliances: {
    padding: 16,
  },
  cartStepContainer: {
    position: 'sticky',
    zIndex: 1,
    top: 0,
    backgroundColor: white,
    padding: '16px 8px 16px 16px',
    width: 379,
    marginRight: SIZE.px16,
  },
}));

export const useHeaderStyles = makeStyles(({ breakpoints }) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    fontSize: SIZE.px30,
    cursor: 'pointer',
  },
  title: {
    '&.MuiTypography-body1': {
      fontSize: SIZE.px24,
      fontWeight: WEIGHT.bolder,
      letterSpacing: 0,
      lineHeight: 1.5,
      marginLeft: SIZE.px16,
      [breakpoints.up('xl')]: {
        fontSize: SIZE.px28,
      },
    },
  },
  cartTitle: {
    '&.MuiTypography-body1': {
      fontSize: SIZE.px24,
      fontWeight: WEIGHT.bolder,
      letterSpacing: 0,
      lineHeight: 1.5,
      [breakpoints.up('xl')]: {
        fontSize: SIZE.px28,
      },
    },
  },
}));

export const useFooterStyles = makeStyles(() => ({
  button: {
    paddingLeft: 26,
    paddingRight: 26,
    lineHeight: 1.25,
  },
  footerContainer: {
    display: 'flex',
    boxShadow: '4px 2px 12px 5px rgba(196,196,196,0.4)',
    zIndex: 1,
    '& div, button': {
      textAlign: 'center',
      width: '50%',
      height: 76,
    },
    '& div': {
      outline: `1px solid ${borderGray}`,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      '& p': {
        fontWeight: WEIGHT.bolder,
        lineHeight: 1.25,
        paddingBottom: 10,
      },
    },
  },
  priceLabel: {
    fontSize: SIZE.px12,
  },
  price: {
    '&.MuiTypography-body1': {
      fontSize: SIZE.px20,
    },
  },
}));

export const useStepStyles = makeStyles(({ breakpoints }) => ({
  stepContainer: {
    marginBottom: 20,
    [breakpoints.up('xl')]: {
      marginBottom: 24,
    },
  },
  stepper: {
    '&.MuiStepper-horizontal': {
      padding: '5px 0 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  step: {
    '&.MuiStepConnector-horizontal': {
      padding: '10px 0',
      width: '48%',
      flex: 'unset ',
    },
  },
  stepPrevious: {
    '&.MuiStepConnector-horizontal': {
      padding: '10px 0',
      width: '48%',
      flex: 'unset',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  line: {
    '&.MuiStepConnector-line': {
      borderWidth: '2px',
    },
  },
  active: {
    '& .MuiStepConnector-lineHorizontal': {
      borderColor: baseBlue,
    },
  },
  label: {
    '& .MuiTypography-body2': {
      color: baseBlue,
      fontWeight: WEIGHT.bolder,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      letterSpacing: 1,
    },
  },
}));

export default useStyles;
