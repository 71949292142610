import React from "react"
import Slider from 'react-slick';
import Testimonial from './Testimonial'
import { useTestimonialStyles } from './Testimonial.styles'

export default function Slideshow() {
    const classes = useTestimonialStyles();

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        arrows: false,
        infinite: false,
        dotsClass: 'slick-dots testimonial-dots'
    }

    return (
            <div className={classes.colorContainer}>
                <div className={classes.container}>
                    <Slider {...settings}>
                        <Testimonial testimonialNumber={1} />
                        <Testimonial testimonialNumber={2} />
                        <Testimonial testimonialNumber={3} />
                    </Slider>
                </div>
            </div>
            
    )

};