import axios from 'axios';

import { BATTERY_REC_API } from '../constants/baseUrls';
import { getCache, setCache } from './cache';

export const getLoanInfo = async (opportunity, env = 'local') => {
  if (!opportunity || !opportunity.state || !opportunity.utility) return;

  const { state, utility, salesDivision, salesPartner, purchasedThrough } =
    opportunity;

  const inputs = {
    state,
    utility,
    salesDivision,
    salesPartner,
    purchasedThrough,
  };

  const cacheKey = JSON.stringify(inputs);
  // check cache first
  let loanInfoCached = getCache(cacheKey);
  if (loanInfoCached) return loanInfoCached;

  try {
    const { data } = await axios.post(
      `${BATTERY_REC_API[env]}/v1/loan-info`,
      inputs
    );
    const loanInfo = data.loanOptions.find((option) => option.term === 10);
    setCache(cacheKey, loanInfo);

    return loanInfo;
  } catch (err) {
    throw new Error(err);
  }
};

export function getMonthlyLoanPrice(totalPrice, rate, numberOfPayments) {
  return (
    (totalPrice * rate * Math.pow(1 + rate, numberOfPayments)) /
    (Math.pow(1 + rate, numberOfPayments) - 1)
  );
}
