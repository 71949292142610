import { makeStyles } from '@material-ui/core';
import {
  baseBlue,
  bgGray,
  borderGray,
  errorMain,
  promptOrange,
  successGreen,
} from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${borderGray}`,
    boxShadow: 'none',
    margin: '5px 0 24px',
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
    '&.hasError': {
      borderColor: `${errorMain} !important`,
    },
    '&.prompt': {
      borderColor: promptOrange,
    },
    '&.success': {
      borderColor: successGreen,
    },
    '&.summary': {
      background: bgGray,
    },
    '&.selected': {
      borderColor: baseBlue,
    },
    '&.smallSpacing': {
      padding: 16,
      margin: '12px 0px',
    },
  },
}));

export default useStyles;
