import { Typography } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { ClassNameMap } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {
  NEXT_STEPS_ACCORDION_SUMMARIES,
  NEXT_STEP_ACCORDION_DETAILS,
} from '../../../constants/landing';
import {
  accordionStyles,
  accordionSummaryStyles,
  accordionDetailsStyles,
  useNextStepsAccordionStyles,
  useExpandedIconStyles,
} from './NextStepsAccordion.styles';

type ExpandedIconProps = {
  isExpanded: boolean;
};

const ExpandedIcon = ({ isExpanded }: ExpandedIconProps) => {
  const classes: ClassNameMap = useExpandedIconStyles();
  return isExpanded ? (
    <RemoveIcon classes={{ root: classes.root }} style={{ fill: 'white' }} />
  ) : (
    <AddIcon classes={{ root: classes.root }} style={{ fill: 'white' }} />
  );
};

const Accordion = withStyles(accordionStyles)(MuiAccordion);

const AccordionSummary = withStyles(accordionSummaryStyles)(
  MuiAccordionSummary
);

const AccordionDetails = withStyles(accordionDetailsStyles)(
  MuiAccordionDetails
);

type NextStepsAccordionItemProps = {
  itemNumber: number;
  expanded: number;
  setExpanded: (index: number) => void;
  isLastItem?: boolean;
};

export default function NextStepsAccordionItem({
  itemNumber,
  expanded,
  setExpanded,
  isLastItem = false,
}: NextStepsAccordionItemProps) {
  const classes: ClassNameMap = useNextStepsAccordionStyles();
  const handleChange =
    (index: number) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
      setExpanded(index);
    };

  const handleNextStepClick = (index: number) => () => setExpanded(index);
  const isItemExpanded = expanded === itemNumber;
  return (
    <Accordion expanded={isItemExpanded} onChange={handleChange(itemNumber)}>
      <AccordionSummary
        expandIcon={<ExpandedIcon isExpanded={isItemExpanded} />}
      >
        <Typography className={classes.listNumber}>{itemNumber + 1}</Typography>
        <Typography className={classes.summary}>
          {NEXT_STEPS_ACCORDION_SUMMARIES[itemNumber]}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className={classes.details}>
          {NEXT_STEP_ACCORDION_DETAILS[itemNumber]}
        </Typography>
        {!isLastItem && (
          <button
            className={classes.nextStep}
            onClick={handleNextStepClick(itemNumber + 1)}
          >
            <Typography>Next Step</Typography>
          </button>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

ExpandedIcon.propTypes = {
  isExpanded: PropTypes.bool,
};

NextStepsAccordionItem.propTypes = {
  itemNumber: PropTypes.number,
  expanded: PropTypes.number,
  setExpanded: PropTypes.func,
  isLastItem: PropTypes.bool,
};
