export const steps = ['Battery', 'Backup'];

export const footerButtonText = [
    'NEXT: Choose backup preferences', 'CHECKOUT'
]

export const toolTipMap = {
    "Battery": "Battery selection and financing",
    "Backup": "Choose backup preferences"
}

