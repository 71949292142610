import { Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import { useBatteryContext } from '../../../context/battery.context';
import { useHeaderStyles } from '../Cart.styles';

const Header = ({ title }) => {
  const classes = useHeaderStyles();
  const { toggleDrawer } = useBatteryContext();

  return (
    <div className={classes.header} role='presentation'>
      <Typography className={classes.cartTitle}>{title}</Typography>
      <Close onClick={toggleDrawer('Close Button')} className={classes.icon} />
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
