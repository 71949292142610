import { Button } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from './Button.styles';

const ButtonComponent = ({
  children,
  className = '',
  variant = 'primary',
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      className={classnames(classes.button, variant, className)}
      {...props}
    >
      {children}
    </Button>
  );
};

ButtonComponent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'rounded',
    'error',
  ]),
};

export default ButtonComponent;
