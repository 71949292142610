import { LAUNCHED_CHAT } from "../constants/mixpanel";
import mixpanel from "../mixpanel/mixpanel";

interface ChatUser {
  prospectId: string;
  name: string;
  email: string;
  phone: string;
}

export const openChatWindow = (user: ChatUser, pageName: string = 'Battery Retrofit Storefront') => {
  mixpanel.track(LAUNCHED_CHAT);
  const chatClientURL = `https://home-c16.incontact.com/incontact/chatclient/chatclient.aspx?poc=54405cbb-0b3f-4de3-a996-8ae8f793cb7d&bu=4595152&p1=6952565&P1=${user.prospectId}&P2=${user.name}&P3=${user.email}&P4=${user.phone}`;
  window.open(chatClientURL, pageName, 'resizable,height=503,width=503');
};
