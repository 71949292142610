import { Button, Typography } from '@material-ui/core';
import React from 'react';
import {
  ChatHeading,
  ChatButtonText,
  ChatHeadingTitle,
} from '../../../constants/landing';
import { useBatteryContext } from '../../../context/battery.context';
import { openChatWindow } from '../../../utils/chat';
import { useChatBannerStyles } from './ChatBanner.styles';

const ChatBanner = () => {
  const classes = useChatBannerStyles();
  const { isDrawerOpen, opportunity, profile } = useBatteryContext();
  const chatWindow = () => {
    const chatUser = {
      prospectId: opportunity.prospectId,
      name: opportunity.fullName,
      email: profile.emailAddress,
      phone: profile.phone,
    }
    openChatWindow(chatUser);
  }

  return (
    <div className={classes.banner}>
      {!isDrawerOpen && (
        <>
          <Typography className={classes.headingTitle}>
            {ChatHeadingTitle}
          </Typography>
          <div className={classes.horizontalLine} />
        </>
      )}
      <Typography className={classes.heading}>{ChatHeading}</Typography>
      <Button
        onClick={chatWindow}
        classes={{
          root: classes.button,
          label: classes.buttonLabel,
        }}
      >
        {ChatButtonText}
      </Button>
    </div>
  );
};

export default ChatBanner;
