import { Card } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from './Card.styles';

const CardComponent = ({
  children,
  className,
  expandable,
  variant = 'default',
  ...props
}) => {
  const classes = useStyles();

  return (
    <Card
      className={classnames(classes.root, variant, className)}
      square
      {...props}
      tabIndex={0}
    >
      {children}
    </Card>
  );
};

CardComponent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  expandable: PropTypes.bool,
  variant: PropTypes.oneOf([
    'default',
    'prompt',
    'success',
    'summary',
    'selected',
    'smallSpacing',
  ]),
};

export default CardComponent;
