import { makeStyles } from '@material-ui/core';

import { baseBlue, titleBlack } from '../../constants/colors';
import { SIZE, WEIGHT } from '../../constants/fonts';

const useStyles = makeStyles(() => ({
  loadingText: {
    '&.MuiTypography-root': {
      fontSize: SIZE.px16,
      lineHeight: 1.5,
      color: titleBlack,
    },
  },
  buttonSelected: {
    '&.MuiButton-root': {
      border: `2px solid ${baseBlue}`,
      '& .MuiButton-label': {
        color: baseBlue,
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 170,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: SIZE.px16,
  },
  priceContainer: {
    textAlign: 'right',
  },
  priceText: {
    '&.MuiTypography-body1': {
      fontSize: SIZE.px24,
      fontWeight: WEIGHT.bold,
      color: titleBlack,
      lineHeight: 1.25,
    },
  },
  priceHelperText: {
    '&.MuiTypography-body1': {
      fontSize: SIZE.px12,
      color: baseBlue,
    },
  },
  loading: {
    color: baseBlue,
    margin: '0 auto',
  },
}));

export default useStyles;
