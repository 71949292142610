export enum SIZE {
  px8 = '0.5rem',
  px12 = '0.75rem',
  px14 = '0.875rem',
  px16 = '1rem',
  px18 = '1.125rem',
  px20 = '1.25rem',
  px24 = '1.5rem',
  px28 = '1.75rem',
  px30 = '1.875rem',
  px32 = '2rem',
  px36 = '2.25rem',
  px40 = '2.5rem',
  px48 = '3rem',
  px56 = '3.5rem',
  px60 = '3.75rem',
  px70 = '4.375rem',
  px72 = '4.5rem',
  px75 = '4.688rem',
  px80 = '5rem',
}

export enum WEIGHT {
  light = 300,
  normal = 400,
  medium = 500,
  bold = 600,
  bolder = 700,
  max = 800,
};
