import React, { useState } from 'react';

import { NO_STATE } from '../../constants/errors';
import { EligibleStates } from '../../constants/info';
import { ERROR_MISSING_STATE } from '../../constants/mixpanel';
import { useBatteryContext } from '../../context/battery.context';
import mixpanel from '../../mixpanel/mixpanel';
import { getQueryParam } from '../../utils/environment';
import Loading from '../Loading/Loading';
import OpportunitySelectorDialog from '../OpportunitySelectorDialog/OpportunitySelectorDialog';
import LandingNoState from './LandingNoState';
import LandingNotEligible from './LandingNotEligible';
import LandingStorefront from './LandingStorefront';

const PageSelector = () => {
  const {
    createRetrofitOppty,
    opportunity,
    parentOpportunities,
    profile,
    setOpportunity,
  } = useBatteryContext();
  const email = profile.emailAddress || getQueryParam('email');
  const state = opportunity?.state || getQueryParam('state');

  const [showOptyDialog, setShowOptyDialog] = useState(true);

  if (opportunity && !state) {
    mixpanel.track(ERROR_MISSING_STATE);
    setOpportunity({
      state: NO_STATE,
    });
  } else if (!state) {
    return (
      <>
        {!opportunity && parentOpportunities && (
          <OpportunitySelectorDialog
            createRetrofitOppty={createRetrofitOppty}
            email={email}
            handleClose={() => {
              setShowOptyDialog(false);
            }}
            open={showOptyDialog}
            opportunities={parentOpportunities}
          />
        )}
        <Loading />
      </>
    );
  }

  return (
    <>
      {state === NO_STATE ? (
        <LandingNoState />
      ) : EligibleStates.includes(state) ? (
        <LandingStorefront />
      ) : (
        <LandingNotEligible />
      )}
    </>
  );
};

export default PageSelector;
