import PropTypes from 'prop-types';
import React from 'react';
import { SELECTED_FINANCING } from '../../constants/mixpanel';

import * as Constants from '../../constants/pricing';
import { useBatteryContext } from '../../context/battery.context';
import mixpanel from '../../mixpanel/mixpanel';
import { getPriceText, FinancialProductType } from '../../utils/strings';
import SectionTitle from '../SectionTitle/SectionTitle';
import LoanPricingCard from './LoanPricingCard';
import PricingCard from './PricingCard';

const PricingInfo = ({ title }) => {
  const { pricingData, pricingSelection, setPricingSelection } =
    useBatteryContext();

  const handleSpacePress = (e) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
      setPricingSelection(FinancialProductType.CASH);
    }
  };

  const onSelectCash = () => {
    if (pricingSelection === FinancialProductType.CASH) return;
    mixpanel.track(SELECTED_FINANCING);
    setPricingSelection(FinancialProductType.CASH);
  };

  const priceText = getPriceText(pricingData?.totalPrice);

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <PricingCard
        title='Cash'
        primaryText={Constants.CASH_PRIMARY_TEXT}
        secondaryText={Constants.CASH_SECONDARY_TEXT}
        price={priceText}
        isSelected={pricingSelection === FinancialProductType.CASH}
        onKeyDown={handleSpacePress}
        onClick={onSelectCash}
      />
      <LoanPricingCard />
    </>
  );
};

PricingInfo.propTypes = {
  title: PropTypes.string,
};

export default PricingInfo;
