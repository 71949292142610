import { makeStyles } from '@material-ui/core';
import { THEME, drawerOpenTheme } from '../../constants/theme';

const useStyles = makeStyles((theme) => ({
  main: { ...THEME(theme) },
  mainThemeShift: { ...drawerOpenTheme(theme)},
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 'calc(100% - 390px)',
  },
}));

export default useStyles;
