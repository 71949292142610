import { makeStyles } from '@material-ui/core';

import { bodyBlack, titleBlack } from '../../constants/colors';
import { SIZE, WEIGHT } from '../../constants/fonts';

const useStyles = makeStyles(() => ({
  title: {
    '&.MuiTypography-root': {
      fontWeight: WEIGHT.medium,
      fontSize: SIZE.px20,
      margin: '20px 0 20px 16px',
      letterSpacing: '0.02em',
      color: titleBlack,
    },
    '.MuiDialog-root &': {
      margin: '36px 0',
    },
    '&.h3': {
      textTransform: 'uppercase',
      color: bodyBlack,
      fontSize: SIZE.px12,
      fontWeight: WEIGHT.bold,
      lineHeight: '150%',
      margin: '0 0 0 16px',
    },
  },
}));

export default useStyles;
