import { makeStyles } from '@material-ui/core';
import { white } from '../../../constants/colors';
import { SIZE, WEIGHT } from '../../../constants/fonts';

export const useFooterStyles = makeStyles((theme) => ({
  footer: {
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#000',
    justifyContent: 'space-between',
    padding: 32,
    [theme.breakpoints.up('md')]: {
      display: 'block',
      padding: '32px 40px',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      padding: '40px 60px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '40px 80px',
    },
  },
  copyright: {
    '&.MuiTypography-body1': {
      color: white,
      fontSize: SIZE.px12,
      letterSpacing: '0.08em',
      lineHeight: 1.5,
      marginBottom: 16,
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: SIZE.px14,
        marginBottom: 0,
      },

      '& strong': {
        color: 'inherit',
        fontWeight: WEIGHT.bolder,
      },
    },
  },
  links: {
    fontSize: SIZE.px12,
    letterSpacing: '0.01em',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      fontSize: SIZE.px14,
    },

    '& li': {
      borderLeft: `1px solid ${white}`,
      display: 'inline',
      padding: '0 8px',
      [theme.breakpoints.down('md')]: {
        border: 'none',
        display: 'block',
        padding: 0,
        marginBottom: 8,
      },

      '& a': {
        color: white,
        lineHeight: 1.5,
      },
    },
    '& li:first-child': {
      borderLeft: 'none',
      paddingLeft: 0,
    },
    '& li:last-child': {
      paddingRight: 0,
      marginBottom: 0,
    },
  },
  linkDivider: {
    color: white,
    padding: '0 10px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
