import { makeStyles } from '@material-ui/core';

import {
  baseBlue,
  titleBlack,
  secondaryTextGray,
  secondaryTextLightGray,
} from '../../../constants/colors';
import { SIZE, WEIGHT } from '../../../constants/fonts';

export const usePriceComparisonStyles = makeStyles((theme) => ({
  batteryText: {
    '&.MuiTypography-body1': {
      fontWeight: WEIGHT.bolder,
      letterSpacing: 1,
      lineHeight: 1.5,
      marginBottom: 25,
      [theme.breakpoints.up('xl')]: {
        fontSize: SIZE.px14,
        lineHeight: 0.18,
        marginBottom: 36,
      },
    },
  },
  disclaimer: {
    '&.MuiTypography-root': {
      color: titleBlack,
      fontSize: SIZE.px12,
      margin: '0 auto',
      maxWidth: 1004,
      padding: 32,
      textAlign: 'center',
      [theme.breakpoints.up('xl')]: {
        padding: '78px 0 89px',
      },
    },
  },
  dots: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  horizontalScrollContainer: {
    display: 'flex',
    justifyContent: 'center',
    overflowX: 'visible',
    padding: 32,

    // hide scrollbar
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    [theme.breakpoints.up('md')]: {
      padding: '24px 40px 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '8px 64px 0',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      overflowX: 'scroll',
    },
    '& fieldset:last-child': {
      marginRight: '0px',
    },
  },
  horizontalScrollContainerShifted: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('lg')]: {
      overflowX: 'scroll',
    },
    [theme.breakpoints.up(1441)]: {
      justifyContent: 'center',
    },
    '& fieldset:last-child': {
      marginRight: '0px',
    },
  },
  hr: {
    border: `1px inset ${titleBlack}`,
    margin: '0 0 8px',
    width: '100%',
  },
  label: {
    '&.MuiTypography-root': {
      color: secondaryTextGray,
      fontSize: SIZE.px14,
      fontWeight: WEIGHT.normal,
      lineHeight: 1.5,
      width: 100,
      [theme.breakpoints.up('xl')]: {
        width: 'auto',
      },
    },
  },
  legend: {
    fontSize: SIZE.px80,
    fontWeight: WEIGHT.max,
    lineHeight: 1.2,
    margin: '0 auto',
    padding: '0 20px',
    width: 'auto',
    [theme.breakpoints.up('xl')]: {
      fontSize: SIZE.px75,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: SIZE.px70,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: SIZE.px60,
    },
  },
  loading: {
    color: baseBlue,
  },
  navigation: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '0 32px',
    [theme.breakpoints.up('md')]: {
      margin: '0 40px',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  navigationText: {
    '&.MuiTypography-root': {
      color: titleBlack,
      fontSize: SIZE.px16,
      fontWeight: WEIGHT.light,
      lineHeight: 1.5,
      marginRight: 24,
      zIndex: 1,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  navigationTextSelected: {
    '&.MuiTypography-root': {
      borderBottom: `2px solid ${baseBlue}`,
      fontWeight: WEIGHT.medium,
      paddingBottom: 5,
    },
  },
  orderButton: {
    marginTop: 8,
    height: 50,
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      marginTop: 20,
      width: 236,
    },
    '&.MuiButton-root.secondary': {
      backgroundColor: baseBlue,

      '& .MuiButton-label': {
        [theme.breakpoints.down('xl')]: {
          fontSize: SIZE.px14,
        },
        [theme.breakpoints.down('lg')]: {
          fontSize: SIZE.px12,
        },
        fontSize: SIZE.px16,
      },
    },
  },
  priceCard: {
    alignItems: 'center',
    border: `1px solid ${secondaryTextLightGray}`,
    boxSizing: 'border-box',
    display: 'flex!important',
    justifyContent: 'space-between',
    flex: '0 0 276px',
    flexDirection: 'column',
    marginRight: 32,
    padding: '0 32px 32px',
    [theme.breakpoints.up('lg')]: {
      marginRight: 40,
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: 88,
    },
  },
  priceCardShift: {
    alignItems: 'center',
    border: `1px solid ${secondaryTextLightGray}`,
    boxSizing: 'border-box',
    display: 'flex!important',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '0 32px 32px',
    [theme.breakpoints.down('lg')]: {
      width: '20%',
    },
  },
  priceInformation: {
    display: 'flex',
    flexDirection: 'column',
  },
  priceInformationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    marginBottom: 8,
  },
  summary: {
    '&.MuiTypography-body1': {
      fontSize: SIZE.px24,
      fontWeight: WEIGHT.light,
      lineHeight: 1.5,
      marginBottom: 30,
      textAlign: 'center',
      width: 144,
      [theme.breakpoints.up('md')]: {
        width: 176,
      },
      [theme.breakpoints.up('xl')]: {
        lineHeight: 1.2,
        marginBottom: 40,
      },
    },
  },
  title: {
    '&.MuiTypography-h2': {
      color: secondaryTextGray,
      fontSize: SIZE.px24,
      fontWeight: WEIGHT.bold,
      lineHeight: 1.5,
      margin: '32px 32px 24px',
      [theme.breakpoints.up('md')]: {
        margin: '32px 40px 24px',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  },
  value: {
    '&.MuiTypography-root': {
      fontSize: SIZE.px18,
      fontWeight: WEIGHT.light,
      lineHeight: 1.5,
      color: secondaryTextGray,
    },
  },
  // totalPrice and savings after label and value so the styles override
  totalPrice: {
    '&.MuiTypography-root': {
      color: titleBlack,
    },
  },
  savings: {
    '&.MuiTypography-root': {
      color: baseBlue,
    },
  },
}));
