export const batteryQuantityText = {
  1: '',
  2: '(2 or more batteries required)',
  3: '(3 batteries required)',
};

export const backupSubtitle = {
  1: 'Please select up to 5 items you want to backup.',
  2: 'Please select up to 10 items you want to backup.',
  3: 'Please select ALL the items you want to backup.',
};
