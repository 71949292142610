import { CircularProgress, Dialog, DialogActions, DialogContent, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import classnames from 'classnames'
import React, { useState } from 'react'

import { failedProposalCreation as failedOpportunityCreation } from '../../constants/errors';
import { Address, formatAddress } from '../../utils/address';
import Button from '../Button/Button'
import { useDialogStyles } from './OpportunitySelectorDialog.styles';

interface Oppty {
  id: string,
  billingInfo: Address
}

interface Props {
  createRetrofitOppty: (opportunity: any, email: string) => void
  email: string
  handleClose: () => void
  open: boolean
  opportunities: Oppty[]
}

const OpportunitySelectorDialog = ({createRetrofitOppty, email, handleClose, open, opportunities}: Props): JSX.Element => {
  const classes = useDialogStyles();
  const [selectedOpptyId, setSelectedOpptyId] = useState(opportunities[0].id);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleRadioSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { target: { value } } = e;
    setSelectedOpptyId(value);
  }

  const handleSelectOpportunity = async () => {
    const selectedParentOppty = opportunities.find(oppty => oppty.id === selectedOpptyId);
    setHasError(false);
    setLoading(true);
    try {
      await createRetrofitOppty(selectedParentOppty, email);
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setHasError(true);
      setLoading(false);
    }
  }

  return (
    <Dialog className={classes.dialog} open={open}>
      <DialogContent>
        <Typography className={classes.info}>We've found several addresses that may support a battery.</Typography>
        <FormLabel className={classes.legend} component="legend">Select an address:</FormLabel>
        <RadioGroup value={selectedOpptyId} onChange={handleRadioSelect} name='parent-opportunities'>
          {opportunities.map(oppty => (
            <FormControlLabel key={oppty.id} className={classnames(classes.radio, { selected: oppty.id === selectedOpptyId })} control={<Radio color="primary" />} value={oppty.id} label={formatAddress(oppty.billingInfo)} />
        ) )}
        </RadioGroup>
        {hasError && <Typography className={classes.error}>{failedOpportunityCreation}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSelectOpportunity} disabled={loading}>
          {loading && <CircularProgress className={classes.loading} size={14} color='inherit' />}
          Select Address
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OpportunitySelectorDialog
