import { Typography } from '@material-ui/core';
import React from 'react';
import {
  ModelDescriptionSubtitle,
  EnergyCapacityTitle,
  OnGridPowerTitle,
  BackupPowerTitle,
  SizeAndWeightTitle,
  InstallationTitle,
  EngergyCapacityDetail,
  OnGridPowerDetail,
  BackupPowerDetail,
  SizeAndWeightDetail,
  InstallationDetail,
  WarrantyDetail,
  ModelDescriptionTitle,
  WarrantyTitle,
} from '../../../constants/landing';
import teslaImage from '../../../images/Tesla.png';
import teslaPowerWallCertifiedImage from '../../../images/teslaPowerWallCertified.png';
import ModelDetail from './ModelDetail';
import { useModelInfoStyles } from './ModelInfo.styles';

const ModelInfo = () => {
  const classes = useModelInfoStyles();
  return (
    <div id='model-info' className={classes.container}>
      <div className={classes.descriptionContainer}>
        <div className={classes.description}>
          <Typography className={classes.descriptionTitle} variant='h2'>
            {ModelDescriptionTitle}
          </Typography>
          <Typography className={classes.descriptionSubtitle}>
            {ModelDescriptionSubtitle}
          </Typography>
          <img
            alt='Tesla Powerwall certified installer'
            className={classes.teslaPowerWallCertifiedImage}
            src={teslaPowerWallCertifiedImage}
          />
        </div>
        <div className={classes.teslaImageContainer}>
          <img
            alt='Tesla Powerwall battery'
            className={classes.teslaImage}
            src={teslaImage}
          />
        </div>
      </div>
      <div className={classes.detailsContainer}>
        <div className={classes.column}>
          <ModelDetail
            title={EnergyCapacityTitle}
            details={EngergyCapacityDetail}
          />
          <ModelDetail title={OnGridPowerTitle} details={OnGridPowerDetail} />
        </div>
        <div className={classes.column}>
          <ModelDetail title={BackupPowerTitle} details={BackupPowerDetail} />
          <ModelDetail
            title={SizeAndWeightTitle}
            details={SizeAndWeightDetail}
          />
        </div>
        <div className={classes.column}>
          <ModelDetail title={InstallationTitle} details={InstallationDetail} />
        </div>
        <div className={classes.column}>
          <ModelDetail title={WarrantyTitle} details={WarrantyDetail} />
        </div>
      </div>
    </div>
  );
};

export default ModelInfo;
