import { CircularProgress, Typography } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import {
  installedPriceLabel,
  orderButtonText,
  priceComparisonSummary,
  savingsLabel,
  totalPriceLabel,
} from '../../../constants/landing';
import { useBatteryContext } from '../../../context/battery.context';
import ArrowRight from '../../../images/ArrowRight';
import getEnv from '../../../utils/environment';
import { getPricing } from '../../../utils/pricing';
import { getPriceText } from '../../../utils/strings';
import Button from '../../Button/Button';
import { usePriceComparisonStyles } from './PriceComparison.styles';

export default function PriceComparisonCard({ numberOfBatteries }) {
  const classes = usePriceComparisonStyles();
  const {
    opportunity,
    setBatteryQuantity,
    toggleDrawer,
    setStep,
    isDrawerOpen,
  } = useBatteryContext();
  const [installedPriceValue, setInstalledPriceValue] = useState(0);

  const updateOrder = (event) => {
    setBatteryQuantity(numberOfBatteries);
    setStep(0);
    if (!isDrawerOpen) {
      toggleDrawer(`${numberOfBatteries} Battery Price Card`)(event);
    }
  };

  useEffect(() => {
    async function requestPricing() {
      const pricingData = await getPricing(
        numberOfBatteries,
        opportunity,
        getEnv(window.location.host)
      );
      setInstalledPriceValue(pricingData.totalPrice);
    }

    if (!opportunity) return;
    requestPricing();
  }, [numberOfBatteries, opportunity]);

  const savingsValue = useMemo(
    () => 0.26 * installedPriceValue,
    [installedPriceValue]
  );
  const totalPriceValue = useMemo(
    () => installedPriceValue - savingsValue,
    [installedPriceValue, savingsValue]
  );

  return (
    <fieldset
      className={isDrawerOpen ? classes.priceCardShift : classes.priceCard}
    >
      <legend className={classes.legend}>{numberOfBatteries}</legend>
      <Typography className={classes.batteryText}>
        {numberOfBatteries === 1 ? 'BATTERY' : 'BATTERIES'}
      </Typography>
      <Typography className={classes.summary}>
        {priceComparisonSummary[numberOfBatteries]}
      </Typography>
      <div className={classes.priceInformationRow}>
        <Typography className={classes.label}>{installedPriceLabel}</Typography>
        {getPriceText(installedPriceValue) ? (
          <Typography className={classes.value}>
            {getPriceText(installedPriceValue)}
          </Typography>
        ) : (
          <CircularProgress className={classes.loading} size={14} />
        )}
      </div>
      <div className={classes.priceInformationRow}>
        <Typography className={classnames(classes.label, classes.savings)}>
          {savingsLabel}
        </Typography>
        {getPriceText(savingsValue) ? (
          <Typography className={classnames(classes.value, classes.savings)}>
            {getPriceText(savingsValue)}
          </Typography>
        ) : (
          <CircularProgress className={classes.loading} size={14} />
        )}
      </div>
      <hr className={classes.hr} />
      <div className={classes.priceInformationRow}>
        <Typography className={classnames(classes.label, classes.totalPrice)}>
          {totalPriceLabel}
        </Typography>
        {getPriceText(totalPriceValue) ? (
          <Typography className={classnames(classes.value, classes.totalPrice)}>
            {getPriceText(totalPriceValue)}
          </Typography>
        ) : (
          <CircularProgress className={classes.loading} size={14} />
        )}
      </div>
      <Button
        variant='secondary'
        className={classes.orderButton}
        onClick={(event) => updateOrder(event)}
      >
        {orderButtonText} <ArrowRight />
      </Button>
    </fieldset>
  );
}

PriceComparisonCard.propTypes = {
  numberOfBatteries: PropTypes.number,
};
