import { makeStyles } from '@material-ui/core';

import {
  baseBlue,
  titleBlack,
  secondaryBgGray,
  secondaryTextGray,
  secondaryTextLightGray,
  faintGray,
} from '../../../constants/colors';
import { SIZE, WEIGHT } from '../../../constants/fonts';

export const useProductComparisonStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: secondaryBgGray,
    display: 'flex',
    flexDirection: 'column',
    padding: 32,
    [theme.breakpoints.up('md')]: {
      padding: '48px 40px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 64,
    },
    [theme.breakpoints.up('xl')]: {
      paddingBottom: 107,
    },
  },
  headline: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  comparisonList: {
    borderLeft: `1px solid ${faintGray}`,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: '0 25px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      borderLeft: 'none',
    },
  },
  comparisonTitle: {
    '&.MuiTypography-h2': {
      color: titleBlack,
      fontSize: SIZE.px32,
      fontWeight: WEIGHT.max,
      lineHeight: 1.2,
      margin: '0 0 8px',
      [theme.breakpoints.up('md')]: {
        fontSize: SIZE.px40,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: SIZE.px72,
        letterSpacing: '0.02em',
        lineHeight: 1.5,
      },
    },
  },
  comparisonSubtitle: {
    '&.MuiTypography-body1': {
      fontSize: SIZE.px24,
      marginBottom: 56,
      [theme.breakpoints.up('md')]: {
        fontSize: SIZE.px32,
        lineHeight: 1.2,
        marginBottom: 64,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: SIZE.px36,
        lineHeight: 1.5,
        marginBottom: 58,
      },
      [theme.breakpoints.up('xl')]: {
        letterSpacing: '0.02em',
      },
    },
  },
  applianceText: {
    alignItems: 'center',
    color: secondaryTextLightGray,
    display: 'flex',
    fontSize: SIZE.px16,
    lineHeight: 1.5,
    margin: '0 0 16px',
  },
  selectedApplianceText: {
    color: secondaryTextGray,
  },
  applianceIcon: {
    marginRight: '20px',
    width: '15px',
  },
  comparisonListSummary: {
    color: secondaryTextGray,
    fontSize: SIZE.px20,
    lineHeight: 1.5,
    marginBottom: 26,
    [theme.breakpoints.up('md')]: {
      marginBottom: 42,
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: 20,
    },
  },
  comparisonListTitle: {
    'span&': {
      borderRight: `3px solid ${baseBlue}`,
      display: 'inline-block',
      fontSize: SIZE.px20,
      fontWeight: WEIGHT.bolder,
      marginRight: 10,
      paddingRight: 8,
      whiteSpace: 'nowrap',
    },
  },
}));
