import { appliances as allAppliances } from '@sunrun/battery-recommendation-service';

export const getOverloadedAppliances = (quantity, selectedAppliances, appliancesList) => {
  if (quantity === 3 || !selectedAppliances.length) return [];
  return selectedAppliances.filter(appliance => quantity < appliancesList.get(appliance).batteryMinimum)
};

export const getApplianceErrors = (appliances, quantity) => {
  const allowedApplianceCount = quantity === 2 ? 10 : 5;
  let exceededLimit = 0;

  if (quantity < 3 && appliances.length > allowedApplianceCount) {
    exceededLimit = allowedApplianceCount;
  }

  const nonAllowedAppliances = getOverloadedAppliances(quantity, appliances, allAppliances);

  return {
    nonAllowedAppliances,
    exceededLimit,
  };
}

export const sortAppliances = (applianceMap, useLoad = false) => {
  if (!applianceMap) return [];

  const applianceArray = [...applianceMap.values()]
    .sort((a, b) => {
      if (!useLoad || a.batteryMinimum === b.batteryMinimum) {
        return a.order - b.order;
      } else {
        return a.batteryMinimum - b.batteryMinimum;
      }
    });

  return applianceArray;
};
